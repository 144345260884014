const CryptoJS = require("crypto-js");
const dataEncryptionKey = 'Rockey/Handsome/PrivateKey';

require('dotenv').config();
export const ENV = {
    appBaseUrl: process.env.REACT_APP_BASE_URL,
    serverUrl: process.env.REACT_APP_SERVER_URL,
    serverLoginUrl: process.env.REACT_APP_LOGIN_URL,
    Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
    x_access_token: JSON.parse(localStorage.getItem("token")),
    x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,
    encryptUserData: function (data) {
        let userData = localStorage.getItem('encuse');
        if (userData) {
            let bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            let originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            if (originalData && originalData.accessToken) {
                data.accessToken = originalData.accessToken;
            }
        }
        data = JSON.stringify({ data });
        let encryptedUser = CryptoJS.AES.encrypt(data, dataEncryptionKey).toString();
        localStorage.setItem('encuse', encryptedUser);
        return true;
    },
    getUserKeys: function (keys = null) {
        let userData = localStorage.getItem('encuse');
        if (userData) {
            var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            var originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            let user = {};
            if (keys) {
                keys = keys.split(" ");
                for (let key in keys) {
                    let keyV = keys[key];
                    user[keyV] = originalData[keyV];
                }
            }
            else {
                user = originalData;
            }
            return user;
        }
        return {};
    }
    ,
    getHeaders: function () {
        let token = JSON.parse(localStorage.getItem('token'));
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };
        if (token) {
            headers["Authorization"] = "Bearer " + token;
            headers["access-token"] = token;
        }
        // if (isFormData) {
        //     delete headers["Accept"];
        //     delete headers["Content-Type"];
        // }
        return headers;
    }
    , logout: function () {
        localStorage.removeItem('token');
        localStorage.removeItem('encuse');
        localStorage.removeItem('user');
        localStorage.removeItem('WalletAddress')

    }
}