import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SiteLogo from "../../assets/images/logo-footer.png";
import { Link } from "react-router-dom";
function Footer(props) {
    return (
        <footer className="footer">
            <div className="footer-top">
                <Container fluid className="container-width">
                    <Row className="">
                        <Col lg={6} md={6}>
                            <div className="site-info">
                                <div className="logo">
                                    <img src={SiteLogo} alt=" Site Logo" className="img-fluid" />
                                </div>
                                <p>Twitter Shilling is an innovative platform to help individuals and companies grow their audience with more authentic and targeted Twitter followers. This blockchain-based Twitter marketing tool will help you build and grow your personal brand or corporate business or simply engage with a larger audience and earn rewards simultaneously.
</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="footer-links">
                                <h3>Products</h3>
                                <ul>
                                    {/* <li>
                                        <Link to="/">Documentation</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/request">Launch App</Link>
                                    </li>
                                    <li>
                                        {/* <Link to="/request">Request Lead</Link> */}
                                        <Link to="/request">Request Lead</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="footer-links">
                                <h3>Social</h3>
                                <ul>
                                    <li>
                                        <a href="https://www.linkedin.com/in/twitter-shilling-263022277/" target="_blank">LinkedIn</a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=100092498619176" target="_blank">Facebook</a>
                                    </li>
                                    <li>
                                        <a href="https://github.com/Twittershilling" target="_blank">Github</a>
                                    </li>
                                    <li><a href="https://twitter.com/twitttershiling" target="_blank">Twitter</a></li>
                                    
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-bottom">
                <Container fluid className="container-width">
                    <div className="copyrights">
                        <p>Copyright 2023 © Twitter Shilling | All Rights Reserved</p>
                    </div>
                </Container>

            </div>
        </footer>
    );
}
export default Footer;
