import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { RequestPackage } from "../../../Redux/actions/requestActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, Link } from "react-router-dom";
import { MetaMaskConnection } from "../../../utils/metaMaskconnect";
import { Approve, Transfer } from "../../../utils/web3";
import FullPageLoader from "../../FullPageLoader/fullPageLoader";
import { toast } from "react-toastify";
import moment from "moment";
function RequestModel(props) {
  let history = useHistory();
  // const [show, setShow] = useState(props.show);
  const [loader, setLoader] = useState(false);
  let WalletAddress = window.localStorage.getItem("WalletAddress");
  const dispatch = useDispatch();
  const closeModel = useSelector((state) =>
    state ? state.gig.closeModel : false
  );

  let data = props.requestData;
  useEffect(() => {
    let token = JSON.parse(window.localStorage.getItem("token"));
    if (!token) {
      window.location.replace("/login");
    }
  }, []);
  let submitRequest = async () => {
    if (WalletAddress) {
      let requestData = {
        ...props.requestData,
        totalPrice:
          +(data.followsPrice.length > 0
            ? data.followsPrice * data.follows
            : 0) +
          +(data.likesPrice.length > 0 ? data.likesPrice * data.likes : 0) +
          +(data.retweetsPrice.length > 0
            ? data.retweetsPrice * data.retweet
            : 0),
      };
      let approveResponse = "";
      setLoader(true);
      approveResponse = await Approve(requestData.totalPrice);
      if (!approveResponse) {
        setLoader(false);
        toast.error("Sorry, Your Transaction is rejected. Please try again");
      }
      if (approveResponse) {
        let transactionResponse = "";
        setLoader(true);
        transactionResponse = await Transfer(
          requestData.totalPrice,
          requestData.userId
        );
        if (transactionResponse.status === true) {
          setLoader(false);
          console.log(requestData)
          let { username, userId, totalPrice, follows, followsPrice, likes, likesPrice, retweet, retweetsPrice, twitterUrl, activationDate, expirationDate } = requestData
          expirationDate = expirationDate + "T23:59:00.000Z"
          console.log(expirationDate)
          const isPaid = true
          const txHash = transactionResponse.transactionHash
          const payload = {
            username,
            userId,
            retweet, retweetsPrice, twitterUrl, activationDate, expirationDate, isPaid, txHash, follows, followsPrice, likes, likesPrice, totalPrice
          }
          dispatch(RequestPackage(payload));
          history.push("/request");
        } else {
          setLoader(false);
          toast.error("Sorry, Your Transaction is rejected. Please try again");
        }
      }
    } else {
      MetaMaskConnection();
    }
    WalletAddress = window.localStorage.getItem("WalletAddress");
  };

  return (
    <div>
      <Modal
        show={props.show}
        //          onHide={() => props.setShowValue(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="totall-bill-model"
      >
        <Modal.Header closeButton>
          <div className="d-flex go-back back-btn-style align-items-center">
            <button
              className="text-black"
              onClick={() => props.justCloseModel(false)}
            >
              <FontAwesomeIcon
                className="me-2 faArrowLeft"
                icon={faArrowLeft}
              />
              Go back
            </button>
          </div>
          <Modal.Title className="title">Total Bill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-gray-box">
            <div className="row post-request-text align-items-center">
              <h3>Post Request 1</h3>
            </div>

            <Row className="align-items-center make-question">
              {loader ? (
                <FullPageLoader />
              ) : (
                <Col sm={12}>
                  <div className="inner-content">
                    <div className="d-flex align-items-center flex-sm-row flex-column">
                      <div className="d-flex">
                        <a>Twitter Post URL:</a>
                      </div>
                      <div className="d-flex questioner-input-field custom-w flex-sm-row flex-column">
                        <input
                          value={data.twitterUrl}
                          type="text"
                          className="form-control form-control-lg"
                          onChange={() => { }}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className="d-flex align-items-center flex-sm-row flex-column mt-1">
                          <div className="d-flex common-w">
                            <p>Total Likes:</p>
                          </div>
                          <div className="d-flex questioner-input-field">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              value={data.likes}
                              onChange={() => { }}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="d-flex align-items-center flex-sm-row flex-column mt-1">
                          <div className="d-flex common-w">
                            <p>Amount for Likes:</p>
                          </div>
                          <div className="d-flex questioner-input-field">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              value={data.likesPrice}
                              onChange={() => { }}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="d-flex align-items-center flex-sm-row flex-column  mt-1">
                          <div className="d-flex common-w">
                            <p>Total Retweets:</p>
                          </div>
                          <div className="d-flex questioner-input-field">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              value={data.retweet}
                              onChange={() => { }}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="d-flex align-items-center flex-sm-row flex-column mt-1">
                          <div className="d-flex common-w">
                            <p>Amount for Retweets:</p>
                          </div>
                          <div className="d-flex questioner-input-field">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              value={data.retweetsPrice}
                              onChange={() => { }}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="d-flex align-items-center flex-sm-row flex-column mt-1">
                          <div className="d-flex common-w">
                            <p>Total Followers:</p>
                          </div>
                          <div className="d-flex questioner-input-field">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              value={data.follows}
                              onChange={() => { }}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="d-flex align-items-center flex-sm-row flex-column mt-1">
                          <div className="d-flex common-w">
                            <p>Amount for Followers:</p>
                          </div>
                          <div className="d-flex questioner-input-field">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              value={data.followsPrice}
                              onChange={() => { }}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="d-flex align-items-center flex-sm-row flex-column mt-1">
                          <div className="d-flex common-w">
                            <p>Start Date:</p>
                          </div>
                          <div className="d-flex questioner-input-field">
                            <input
                              type="date"
                              className="form-control form-control-lg"
                              value={data.activationDate}
                              onChange={() => { }}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="d-flex align-items-center flex-sm-row flex-column mt-1">
                          <div className="d-flex common-w">
                            <p>End Date:</p>
                          </div>
                          <div className="d-flex questioner-input-field">
                            <input
                              type="date"
                              className="form-control form-control-lg"
                              value={data.expirationDate}
                              onChange={() => { }}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </Col>
              )}
            </Row>
          </div>

          <div className="row post-request-text align-items-center mt-4">
            <Col sm={6}>
              <h2>Total amount to pay:</h2>
            </Col>
            <Col sm={6}>
              <div className="title-2">
                {(data.follows.length > 0 ? data.follows : 0) *
                  (data.followsPrice.length > 0 ? data.followsPrice : 0) +
                  (data.likes.length > 0 ? data.likes : 0) *
                  (data.likesPrice.length > 0 ? data.likesPrice : 0) +
                  (data.retweet.length > 0 ? data.retweet : 0) *
                  (data.retweetsPrice.length > 0
                    ? data.retweetsPrice
                    : 0)}{" "}
                PRPL
              </div>
            </Col>
          </div>

          <div className="d-flex flex-fill justify-content-center mt-4 mb-2">
            <button className="btn-yellow make-request-btn2 btn" onClick={submitRequest}>
              Checkout
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default connect("", { RequestPackage })(RequestModel);
