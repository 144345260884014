import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import { useParams, useHistory, Link } from "react-router-dom";
import { ViewGigBYId } from "./../../Redux/actions/requestActions";
import { connect } from "react-redux";
import { OnboardingButton } from "../../utils/metaMaskconnect";
import Twitter from "../RequestPage/Twitter/twitter";
import { ProgressBar } from "react-bootstrap";
function PrplDashborad(props) {
  const { id } = useParams();
  let history = useHistory();
  const [data, setData] = useState();
  const [bidRequest, setBidRequest] = useState();
  let complete_percentage = " 0";
  useEffect(() => {
    props.ViewGigBYId(id);
  }, []);

  useEffect(() => {
    const { viewGig } = props.list;
    setData(viewGig[0]);
  }, [props.list.viewGig[0]]);
  useEffect(() => {
    const { viewAcceptedGig } = props.list;
    setBidRequest(viewAcceptedGig);
  }, [props.list.viewAcceptedGig]);
  {
    complete_percentage = Math.floor(
      (((data?.likesCount
        ? data.likesCount
        : 0) + (data?.retweetsCount
          ? data?.retweetsCount
          : 0) + (data?.followsCount
            ? data.followsCount
            : 0)) /
        (data?.likes + data?.retweet + data?.follows)) *
      100
    );
  }

  return (
    <section id="request" className="custom-dashboard">
      <div className="">
        <Row className="align-items-center">
          <Col xl={4}>
            <h6>PRPL Dashboard</h6>
          </Col>
          <Col xl={8} className="mt-xl-0 mt-3 mb-xl-0 mb-3">
            <div className="connect d-flex align-items-center">
              <OnboardingButton />
              <Twitter />
            </div>
          </Col>
          <Col xl={12} className="mt-xl-3 mt-1">
            <div className="d-flex go-back back-btn-style">
                <button
                  onClick={() => { history.goBack() }
                  }
                >
                  <FontAwesomeIcon
                    className="me-2 faArrowLeft"
                    icon={faArrowLeft}
                  />
                  Go back
                </button>

              </div>
          </Col>
        </Row>
        <div className="prpl-dashboard inner-content">
          <div className="">
            <Row className="align-items-center justify-content-center progress-content">
              <Col xl={12}>
                <div className="d-flex align-items-sm-center flex-sm-row flex-column">
                  <div className="d-flex fixed-w">
                    <h4>Progress</h4>
                  </div>
                  <div className=" d-flex flex-fill">
                    <ProgressBar
                      now={complete_percentage}
                      label={`${complete_percentage} %`}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center post-url mt-3 flex-sm-row flex-column">
              <Col xl={12}>
                <div className="d-flex align-items-sm-center flex-sm-row flex-column">
                  <div className="d-flex fixed-w">
                    <h6>Twitter Post URL</h6>
                  </div>
                  <div className="d-flex post_url-field questioner-input-field flex-fill">
                    <div className="custom-design-field">
                      <p>{data?.twitterUrl}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {data?.likes ? (
              <Row className="align-items-center justify-content-center progress-content  post-url mt mt-5 flex-sm-row flex-column">
                <Col xl={12}>
                  <div className="d-flex align-items-sm-center flex-sm-row flex-column">
                    <div className="d-flex fixed-w">{/* <h6></h6> */}</div>
                    <div className="d-flex  flex-fill">
                      <h4>Total Likes:</h4>
                      <h4>{data?.likes}</h4>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
            {data?.follows ? (
              <Row className="align-items-center justify-content-center progress-content  post-url mt mt-4 flex-sm-row flex-column">
                <Col xl={12}>
                  <div className="d-flex align-items-sm-center flex-sm-row flex-column">
                    <div className="d-flex fixed-w">{/* <h6></h6> */}</div>
                    <div className="d-flex  flex-fill align-items-center ">
                      <h4>Total Follow:</h4>
                      <h4>{data?.follows}</h4>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}

            {data?.retweet ? (
              <Row className="align-items-center justify-content-center progress-content post-url mt mt-4 flex-sm-row flex-column">
                <Col xl={12}>
                  <div className="d-flex align-items-sm-center flex-sm-row flex-column">
                    <div className="d-flex fixed-w">{/* <h6></h6> */}</div>
                    <div className="d-flex  flex-fill">
                      <h4>Total Retweets:</h4>
                      <h4>{data?.retweet}</h4>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
        <div className="table-responsive">
          <Table className="tab-one-table" style={{ marginBottom: "0" }}>
            <thead>
              <tr>
                <th className="">Wallet Address</th>
                <th className="text-center">User</th>
                <th className="text-center">Requests Performed</th>
                <th className="text-center">Pending Requests</th>
                <th className="text-center">prpl rewards cLAIMED</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bidRequest && bidRequest?.length > 0
                ? bidRequest.map((datas, key) => {
                  return (
                    <tr key={key}>
                      <td>{datas?.wallet_address}</td>
                      <td className="name-add">{datas?.username}</td>
                      <td>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div className="d-flex">
                            <div className="social-icons table-social-links">
                              <ul>
                                {data?.likes ? (
                                  <>
                                    {datas?.likeStatus === 1 ? (
                                      <li>
                                        <a
                                          href={data?.twitterUrl}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FontAwesomeIcon icon={faHeart} />
                                        </a>
                                      </li>
                                    ) : null}
                                  </>
                                ) : null}
                                {data?.retweet ? (
                                  <>
                                    {datas?.retweetStatus === 1 ? (
                                      <li>
                                        <a
                                          href={data?.twitterUrl}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FontAwesomeIcon icon={faRetweet} />
                                        </a>
                                      </li>
                                    ) : null}
                                  </>
                                ) : null}
                                {data?.follows ? (
                                  <>
                                    {datas?.followStatus === 1 ? (
                                      <li>
                                        <a
                                          href={data?.twitterUrl}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FontAwesomeIcon
                                            icon={faUserPlus}
                                          />
                                        </a>
                                      </li>
                                    ) : null}
                                  </>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <div className="d-flex">
                            <div className="social-icons table-social-links">
                              <ul>
                                {data?.likes ? (
                                  <>
                                    {datas?.likeStatus === 0 ||
                                      datas?.likeStatus === 2 ? (
                                      <li>
                                        <a
                                          href={data?.twitterUrl}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FontAwesomeIcon icon={faHeart} />
                                        </a>
                                      </li>
                                    ) : null}
                                  </>
                                ) : null}
                                {data?.retweet ? (
                                  <>
                                    {datas?.retweetStatus === 0 ||
                                      datas?.retweetStatus === 2 ? (
                                      <li>
                                        <a
                                          href={data?.twitterUrl}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FontAwesomeIcon icon={faRetweet} />
                                        </a>
                                      </li>
                                    ) : null}
                                  </>
                                ) : null}
                                {data?.follows ? (
                                  <>
                                    {datas?.followStatus === 0 ||
                                      datas?.followStatus === 2 ? (
                                      <li>
                                        <a
                                          href={data?.twitterUrl}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FontAwesomeIcon
                                            icon={faUserPlus}
                                          />
                                        </a>
                                      </li>
                                    ) : null}
                                  </>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column text-black">
                          {datas?.rewards} PRPL
                        </div>
                      </td>
                      <td className="text-center view-req">
                        <Link to={`/request-action/${datas?._id}`}>
                          <div className="shape-up d-flex">
                            <FontAwesomeIcon icon={faChevronDown} />
                          </div>
                          View Details
                        </Link>
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </Table>
        </div>
      </div>
    </section>
  );
}
const mapStateToProps = (state) => ({
  list: state.gig,
  error: state.error,
});
export default connect(mapStateToProps, { ViewGigBYId })(PrplDashborad);
