import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import {
  uploadProof,
  acceptedGigbyId,
} from "../../../Redux/actions/requestActions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
import { ENV } from "../../../config/config";
import {
  OnboardingButton,
  MetaMaskConnection,
} from "../../../utils/metaMaskconnect";
import Twitter from "./../Twitter/twitter";
import { ProgressBar } from "react-bootstrap";
import FullPageLoader from "../../FullPageLoader/fullPageLoader";
const serverUrl = ENV.serverUrl;
const AcceptedRequestDetail = () => {
  const user = ENV.getUserKeys();
  const WalletAddress = localStorage.getItem("WalletAddress");
  const count = useSelector((state) => state?.gig.count);
  const acceptedGig = useSelector((state) => state?.gig.acceptedGigbyId);
  const userId = user?.data._id;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState("1");
  const [totalPage, setTotalPage] = useState(null);
  const [limit, setLimit] = useState("10");
  const [totalgigs, setTotalgigs] = useState(null);
  const [errors, setErrors] = useState("");
  const [filter, setFilter] = useState("createdAt");
  const [loader, setLoader] = useState(true);
  const [imageChanged, setImageChanged] = useState(false);
  const [avatar, setAvatar] = useState({
    likeAvatar: "",
    likeAvatarName: "",
    followAvatar: "",
    followAvatarName: "",
    retweetAvatar: "",
    retweetAvatarName: "",
  });
  const [selectedId, setSelectedId] = useState("");
  const { id } = useParams();
  let history = useHistory();
  let complete_percentage = " 0";
  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      window.location.replace("/login");
    }
  }, []);
  useEffect(() => {
    if (
      avatar.likeAvatarName !== "" ||
      avatar.followAvatarName !== "" ||
      avatar.retweetAvatarName !== ""
    ) {
      setAvatar({
        likeAvatar: "",
        likeAvatarName: "",
        followAvatar: "",
        followAvatarName: "",
        retweetAvatar: "",
        retweetAvatarName: "",
      });
    }
    dispatch(acceptedGigbyId(id));
  }, [count]);
  const onChangeImage = async (e, id) => {
    setSelectedId(id);
    let avat;
    let avatName;
    avat = e.target.name + "Avatar";
    avatName = e.target.name + "AvatarName";
    const files = e.target.files;

    if (files) {
      if (files[0].type === "image/png" || files[0].type === "image/jpg" || files[0].type === "image/jpeg") {
        // if(e.target.name=='retweet'){
        //   setReTweetLabel('uploaded')
        // }
        // if(e.target.name=='like'){
        //   setLikeLabel('uploaded')
        // }
        // if(e.target.name=='follow'){
        //   setFollowLabel('uploaded')
        // }
        setAvatar({ [avat]: files, [avatName]: files[0].name });
        setImageChanged(true)
        return;
      }
      else {
        toast.error("Only jpg,jpeg and png files are allowed!")
      }
    }
  };

  useEffect(() => {
    setLoader(false)
console.log(acceptedGig)
  }, [acceptedGig])
  const onSubmit = async(type, typeName, uId, gId) => {
    let avat;
    let avatName;
    avat = typeName + "Avatar";
    avatName = typeName + "AvatarName";
    const myAvatar = avatar[[avat]];

    const avatNamee = avatar[[avatName]];

    let formData = new FormData();
    if (avatNamee === undefined || avatNamee === "") {
      toast.error("Please upload the image first!");
    } else if (selectedId !== gId) {
      toast.error("Please upload the image first!");
    } else {
      setErrors("");

      if (myAvatar === "") {
        formData.append("image", myAvatar);
      } else {
        formData.append("image", myAvatar[0], avatNamee);
      }
      formData.append("userId", uId);
      formData.append("gigId", gId);
      formData.append("type", type);
      if (WalletAddress) {
       let imageUploaded =await dispatch(uploadProof(formData));
       setImageChanged(!imageUploaded)
       debugger
      } else {
        MetaMaskConnection();
      }
      // setAvatar({ likeAvatar: "", likeAvatarName: "", followAvatar: "", followAvatarName: "", retweetAvatar: "", retweetAvatarName: "" })
    }
  };
  complete_percentage = Math.floor(
    (((acceptedGig?.gig?.likesCount
      ? acceptedGig?.gig?.likesCount
      : 0) + (acceptedGig?.gig?.retweetsCount
        ? acceptedGig?.gig?.retweetsCount
        : 0) + (acceptedGig?.gig?.followsCount
          ? acceptedGig?.gig?.followsCount
          : 0)) /
      (acceptedGig?.gig?.likes +
        acceptedGig?.gig?.retweet +
        acceptedGig?.gig?.follows)) *
    100
  );

  return (
    <>

      {loader ? (<FullPageLoader />) : (<Card>
        <Card.Header>
          <Row className="align-items-center mb-5">
            <Col xl={6}>
              <div className="header-logo">
                <img src="/images/logo.png" alt="Logo" className="img-fluid" />
              </div>
            </Col>
            <Col xl={6} className="mt-xl-0 mt-3 mb-xl-0 mb-3">
              <div className="connect">
                <OnboardingButton />
                <Twitter />
              </div>
            </Col>
          </Row>
          <div className="d-flex go-back back-btn-style mb-3">
            <button
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon
                className="me-2 faArrowLeft"
                icon={faArrowLeft}
              />
              Go back
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table
              className="request-table tab-one-table"
              style={{ marginBottom: "0" }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Request</th>
                  <th>Remaining</th>
                  <th>Total</th>
                  <th>Price per request</th>
                  <th className="text-center">pprl rewards</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <div className="d-flex flex-column">
                      <div className="d-flex">
                        <div className="social-icons table-social-links">
                          <ul>
                            <li>
                              {acceptedGig?.gig?.likes ? (
                                <a
                                  href={acceptedGig?.gig?.twitterUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FontAwesomeIcon icon={faHeart} />
                                </a>
                              ) : null}
                            </li>
                            <li>
                              {acceptedGig?.gig?.retweet ? (
                                <a
                                  href={acceptedGig?.gig?.twitterUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FontAwesomeIcon icon={faRetweet} />
                                </a>
                              ) : null}
                            </li>
                            <li>
                              {acceptedGig?.gig?.follows ? (
                                <a
                                  href={acceptedGig?.gig?.twitterUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FontAwesomeIcon icon={faUserPlus} />
                                </a>
                              ) : null}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex next-page-info">
                        <a href={acceptedGig?.gig?.twitterUrl}>Tweet Url</a>
                      </div>
                    </div>
                  </td>
                  <td className="custome-de-pr">
                    <ProgressBar
                      now={complete_percentage}
                      label={`${complete_percentage}
                            %`}
                    />
                    <p className="text-center">
                      {acceptedGig?.gig?.likes
                        ? `Likes : ${acceptedGig?.gig?.likes - acceptedGig?.gig?.likesCount}`
                        : null}{" "}
                      {acceptedGig?.gig?.follows
                        ? `Follow : ${acceptedGig?.gig?.follows - acceptedGig?.gig?.followsCount}`
                        : null}{" "}
                      {acceptedGig?.gig?.retweet
                        ? `Retweets : ${acceptedGig?.gig?.retweet - acceptedGig?.gig?.retweetsCount}`
                        : null}
                    </p>
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      {acceptedGig?.gig?.likes ? (
                        <p>Like: {acceptedGig?.gig?.likes}  </p>
                      ) : null}
                      {acceptedGig?.gig?.follows ? (
                        <p>Follow: {acceptedGig?.gig?.follows}  </p>
                      ) : null}
                      {acceptedGig?.gig?.retweet ? (
                        <p>Retweet: {acceptedGig?.gig?.retweet}  </p>
                      ) : null}
                    </div></td>
                  <td>
                    <div className="d-flex flex-column">
                      {acceptedGig?.gig?.likesPrice ? (
                        <p>Like: {acceptedGig?.gig?.likesPrice} PRPL </p>
                      ) : null}
                      {acceptedGig?.gig?.followsPrice ? (
                        <p>Follow: {acceptedGig?.gig?.followsPrice} PRPL </p>
                      ) : null}
                      {acceptedGig?.gig?.retweetsPrice ? (
                        <p>Retweet: {acceptedGig?.gig?.retweetsPrice} PRPL </p>
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-prpl justify-content-center">
                      <p>{acceptedGig?.gig?.totalPrice} PRPL</p>
                    </div>
                  </td>
                </tr>
                {acceptedGig?.gig?.likes ? (
                  <tr>
                    <td>&nbsp;</td>
                    <td className="text-purple">
                      <p title={`${acceptedGig?.gig?._id}`}>Like</p>
                    </td>

                    <td className="text-center">
                      <div className="addres-box questioner-input-field">
                        <input
                          value={acceptedGig?.gig?.twitterUrl}
                          type="text"
                          className="form-control form-control-lg"
                          onChange={() => { }}
                          readOnly
                        />
                      </div>
                    </td>
                    {acceptedGig?.likeStatus === 0 ||
                      acceptedGig?.likeStatus === 2 ||
                      acceptedGig?.likeStatus === null ? (
                      <>
                        <td>
                          {/* {
                                                                        selectedId === data.gigId ?
                                                                            avatar.likeAvatarName !== "" ? <span>{avatar.likeAvatarName}</span> : ""

                                                                            :
                                                                            ""
                                                                    } */}
                          <input
                            type="file"
                            id="like"
                            name="like"
                            onChange={(e) =>
                              onChangeImage(e, acceptedGig?.gigId)

                            }
                            accept="image/*"
                          />
                          <label htmlFor="like" className="btn-2">
                            {avatar.likeAvatar?'uploaded':'upload'}
                          </label>
                        </td>
                        <td className="text-center">
                          <button 
                            disabled={acceptedGig?.likeImage && !avatar.likeAvatar }
                            className="btn btn-yellow"
                            onClick={(e) => {
                              onSubmit(
                                1,
                                "like",
                                acceptedGig?.userId,
                                acceptedGig?.gigId
                              );
                            }}
                          >
                           {acceptedGig?.likeImage && !avatar.likeAvatar? "Submitted":"Submit"}
                          </button>
                        </td>
                        {acceptedGig?.likeStatus === 2 ? (
                          <td>
                          
                              <Badge bg="danger">Rejected</Badge>
                            
                          </td>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <td className="text-center">
                      
                          <Badge bg="success"> Accepted</Badge>
                      </td>
                    )}
                    <td>
                      {acceptedGig?.likeImage ? (
                        <a
                          href={`${serverUrl + acceptedGig?.likeImage}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Image
                        </a>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ) : null}
                {acceptedGig?.gig?.follows ? (
                  <tr>
                    <td>&nbsp;</td>
                    <td className="text-purple">
                      <p>Follow</p>
                    </td>

                    <td className="text-center">
                      <div className="addres-box questioner-input-field">
                        <input
                          value={acceptedGig?.gig?.twitterUrl}
                          type="text"
                          className="form-control form-control-lg"
                          onChange={() => { }}
                          readOnly
                        />
                      </div>
                    </td>
                    {acceptedGig?.followStatus === 0 ||
                      acceptedGig?.followStatus === 2 ||
                      acceptedGig?.followStatus === null ? (
                      <>
                        <td>
                          {/* {
                                                                        selectedId === acceptedGig?.gigId ?
                                                                            avatar.followAvatarName !== "" ? <span>{avatar.followAvatarName}</span> : ""

                                                                            :
                                                                            ""
                                                                    } */}

                          <input
                            type="file"
                            id="file"
                            name="follow"
                            accept="image/*"
                            onChange={(e) =>
                              onChangeImage(e, acceptedGig?.gigId)
                            }

                          />
                          <label htmlFor="file" className="btn-2">
                          {avatar.followAvatar?'uploaded':'upload'}
                          </label>
                        </td>
                        <td className="text-center">
                          <button
                            disabled={acceptedGig?.followImage && !avatar.followAvatar}
                            className="btn btn-yellow"
                            onClick={(e) => {
                              onSubmit(
                                2,
                                "follow",
                                acceptedGig?.userId,
                                acceptedGig?.gigId
                              );
                            }}
                          >
                          {acceptedGig?.followImage && !avatar.followAvatar ?"Submitted":"Submit"}

                          </button>
                        </td>
                        {acceptedGig?.followStatus === 2 ? (
                          <td>
                            
                              <Badge bg="danger"> Rejected</Badge>
                           {" "}
                          </td>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <td className="text-center">
                       
                          <Badge bg="success"> Accepted</Badge>
                      </td>
                    )}
                    <td>
                      {acceptedGig?.followImage ? (
                        <a
                          href={`${serverUrl + acceptedGig?.followImage}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Image
                        </a>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ) : null}
                {acceptedGig?.gig?.retweet ? (
                  <tr>
                    <td>&nbsp;</td>
                    <td className="text-purple">
                      <p>Retweet</p>
                    </td>

                    <td className="text-center">
                      <div className="addres-box questioner-input-field">
                        <input
                          value={acceptedGig?.gig?.twitterUrl}
                          type="text"
                          className="form-control form-control-lg"
                          onChange={() => { }}
                          readOnly
                        />
                      </div>
                    </td>
                    {acceptedGig?.retweetStatus === 0 ||
                      acceptedGig?.retweetStatus === 2 ||
                      acceptedGig?.retweetStatus === null ? (
                      <>
                        <td>
                          {/* {
                                                                    selectedId === acceptedGig?.gigId ?
                                                                        avatar.retweetAvatarName !== "" ? <span>{avatar.retweetAvatarName}</span> : ""

                                                                        :
                                                                        ""
                                                                } */}

                          <input
                            type="file"
                            id="retweet"
                            name="retweet"
                            onChange={(e) =>
                              onChangeImage(e, acceptedGig?.gigId)
                            }
                            accept="image/*"
                          />
                          <label htmlFor="retweet" className="btn-2">
                          {avatar.retweetAvatar?'uploaded':'upload'}
                          </label>
                        </td>
                        <td className="text-center">
                          <button
                            disabled={acceptedGig?.retweetImage && !avatar.retweetAvatar}
                            className="btn btn-yellow"
                            onClick={() => {
                              onSubmit(
                                3,
                                "retweet",
                                acceptedGig?.userId,
                                acceptedGig?.gigId
                              );
                            }}
                          >
                           {acceptedGig?.retweetImage && !avatar.retweetAvatar? "Submitted":"Submit"}

                          </button>
                        </td>
                        {acceptedGig?.retweetStatus === 2 ? (
                          <td>
                            
                              <Badge bg="danger"> Rejected</Badge>
                            {" "}
                          </td>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <td className="text-center">
                        
                          <Badge bg="success"> Accepted</Badge>
                       
                      </td>
                    )}
                    <td>
                      {acceptedGig?.retweetImage ? (
                        <a
                          href={`${serverUrl + acceptedGig?.retweetImage}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Image
                        </a>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>)}
    </>
  );
};
export default AcceptedRequestDetail;
