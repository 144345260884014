import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './Redux/Reducers/combineReducers'
import { toast, ToastContainer } from 'react-toastify';
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from './components/scroll-to-top/scroll-to-top'

import 'react-toastify/dist/ReactToastify.css'
toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  newestOnTop: true,
  autoClose: 3000
});
ReactDOM.render(
  <Provider store={store}>
    <ToastContainer />
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>

  </Provider>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
