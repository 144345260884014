import axios from "axios";
import { ENV } from './../../config/config';
import { toast } from 'react-toastify';

const baseUrl = process.env.REACT_APP_SERVER_URL


function apiHelper(apiType, path, data, params) {
      const xauthtoken = JSON.parse(localStorage.getItem("token"))
    if (apiType === "post" || apiType === "put") {
        // return new Promise(function (resolve, reject) {
        return axios({
            method: apiType,
            url: `${baseUrl + path}`,
            data,
            headers: {
                'x-access-token': xauthtoken,
                'x-auth-token': ENV.x_auth_token
            },
            body: JSON.stringify(data),
        }).then(res => {
        
            return res
        }).catch(err => {
          
            toast.error(err)
        }
        );
        // })

    } else if (apiType === "get" || apiType === "delete") {
        // return new Promise(function (resolve, reject) {
        return axios({
            method: apiType,
            url: `${baseUrl + path}`,
            data,
            headers: {
                'x-access-token': xauthtoken,
                'x-auth-token': ENV.x_auth_token
            }
        }).then(res => {
           
            return res;

        }).catch(err => {
            toast.error(err)
        }
        );
        // })
    }
}


export { apiHelper };
