import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { RequestsList } from "../../../Redux/actions/requestActions";
import { getUser } from "../../../Redux/actions/authActions";
import DashboradHeader from "../../DashboardHeader/DashboardHeader";
import { ENV } from "../../../config/config";
import { MetaMaskConnection } from "../../../utils/metaMaskconnect";
import { ClaimPrpl } from "../../../utils/web3";
import FullPageLoader from "../../FullPageLoader/fullPageLoader";
import moment from "moment";
function ActivePage() {

  const [currentPage, setCurrentPage] = useState("1");
  const [totalPage, setTotalPage] = useState(null);
  const [limit, setLimit] = useState("10");
  const [filter, setFilter] = useState("createdAt");
  const [loader, setLoader] = useState(true);
  const user = ENV.getUserKeys();
  const gigs = useSelector((state) => state?.gig.gigs);
  const loggedInUser = useSelector((state) => state?.auth.userData);
  const paginations = useSelector((state) => state?.gig.pagination);
  const dispatch = useDispatch();
  const id = " ";
  let token = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    dispatch(getUser(user.data?._id));
  }, []);
  useEffect(() => {
    if (!token) {
      window.location.replace("/login");
    } else {
      dispatch(RequestsList(id, currentPage, limit, filter));
      setLoader(true);
    }
  }, [token]);
  useEffect(() => {
    setCurrentPage(paginations.page);
    setTotalPage(paginations.pages);
    setLoader(false);
  }, [paginations]);
  const handleSelect = (e) => {
    setLimit(e);
    dispatch(RequestsList(id, 1, e, filter));
  };
  const prevPage = () => {
    setCurrentPage(currentPage - 1);

    if (currentPage >= 1) {
      dispatch(RequestsList(id, currentPage - 1, limit, filter));
    }
  };
  const nextPage = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage >= 1 && currentPage <= totalPage) {
      dispatch(RequestsList(id, currentPage + 1, limit, filter));
    }
  };
  const FilterData = (e) => {
    setFilter(e.target.value);
    dispatch(RequestsList(id, currentPage, limit, e.target.value));
  };
  const claimPRPL = async () => {
    let Wallet_Address = await localStorage.getItem("WalletAddress");

    if (Wallet_Address) {
      await ClaimPrpl(user.data?.rewards);
    } else {
      MetaMaskConnection();
    }
  };

  return (
    <div className="tabone-content active-page">
      <DashboradHeader />
      {loader ? (
        <FullPageLoader />
      ) : (
        <Card>
          <Card.Header>
            <div className="d-flex flex-md-row flex-column justify-content-between">
              <div className="d-flex justify-content-between align-items-center mt-md-0 mt-3">
                <div className="d-flex filters-action me-4">
                  <button className="btn btn-white" onChange={FilterData}>
                    <FontAwesomeIcon className="filter-color" icon={faFilter} />
                    Filter
                    <div className="dropdown-menu">
                      <div className="d-flex">
                        <p>value by:</p>
                      </div>
                      <div className="d-flex justify-content-lg-between transition option-values">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="f-option"
                            value="createdAt"
                            name="selector"
                          />
                          <label htmlFor="f-option">All</label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-lg-between transition option-values">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="s-option"
                            value="likesPrice"
                            name="selector"
                          />
                          <label htmlFor="s-option">Like</label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-lg-between transition option-values">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="t-option"
                            value="followsPrice"
                            name="selector"
                          />
                          <label htmlFor="t-option">Follow</label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-lg-between transition option-values">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="R-option"
                            value="retweetsPrice"
                            name="selector"
                          />
                          <label htmlFor="R-option">Retweet</label>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
                {/* <div className="d-flex">
                  <Link to="" className="learn-more">
                    Learn more
                    <FontAwesomeIcon
                      className="filter-color"
                      icon={faExternalLinkAlt}
                    />
                  </Link>
                </div> */}
              </div>
              {loggedInUser.data?.type === 2 ? <div className="d-flex justify-content-between align-items-center mt-md-0 mt-3">
                <div className="d-flex me-4 text-prpl">
                  <p>
                    {loggedInUser.data?.rewards}
                    <span>PRPL</span>
                  </p>
                </div>
                <div className="d-flex">
                  <Link to="/claim"
                    className="btn btn-yellow"
                  >
                    Claim PRPL
                  </Link>
                </div>
              </div> : null}
            </div>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <Table className="tab-one-table" style={{ marginBottom: "0" }}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Request</th>
                    <th>Expire Date</th>
                    <th>Total </th>
                    <th>Remaining</th>
                    <th>Price per request</th>
                    <th className="text-center">pprl rewards</th>
                  </tr>
                </thead>
                <tbody>
                  {gigs && gigs.length > 0
                    ? gigs.map((data, key) => {
                      const today = new Date()
                      return (
                        <>
                          {
                            (data.likes - data.likesCount) === 0 && (data.follows - data.followsCount) === 0 && (data.retweet - data.retweetsCount) === 0 ? null :
                              <tr key={key}>
                                <td className="text-center view-req">
                                  <Link to={`/request-view/${data._id}`}>
                                    <div className="shape-up d-flex">
                                      <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    View Request
                                  </Link>
                                </td>
                                <td>
                                  <div className="d-flex flex-column">
                                    <div className="d-flex">
                                      <div className="social-icons table-social-links">
                                        <ul>
                                          <li>
                                            {data.likes ? (
                                              <a
                                                href={data?.twitterUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <FontAwesomeIcon icon={faHeart} />
                                              </a>
                                            ) : null}
                                          </li>
                                          <li>
                                            {data.retweet ? (
                                              <a
                                                href={data?.twitterUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faRetweet}
                                                />
                                              </a>
                                            ) : null}
                                          </li>
                                          <li>
                                            {data.follows ? (
                                              <a
                                                href={data?.twitterUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faUserPlus}
                                                />
                                              </a>
                                            ) : null}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="d-flex next-page-info">
                                      <Link to={data?.twitterUrl}>Tweet Url</Link>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column">
                                    <p> {moment.utc(data?.expirationDate).format("DD MMM YYYY")}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column colum-fix-w">
                                    {data.likes ? (
                                      <p>Like: {data.likes} </p>
                                    ) : null}
                                    {data.follows ? (
                                      <p>Follow: {data.follows} </p>
                                    ) : null}
                                    {data.retweet ? (
                                      <p>Retweet: {data.retweet} </p>
                                    ) : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column colum-fix-w">
                                    {data.likes ? (
                                      <p>Like: {data.likes - data.likesCount}</p>
                                    ) : null}
                                    {data.follows ? (
                                      <p>
                                        Follow:{" "}
                                        {data.follows - data.followsCount}{" "}
                                      </p>
                                    ) : null}
                                    {data.retweet ? (
                                      <p>
                                        Retweet:{" "}
                                        {data.retweet - data.retweetsCount}{" "}
                                      </p>
                                    ) : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex flex-column colum-fix-w">
                                    {data.likesPrice ? (
                                      <p>Like: {data.likesPrice} PRPL </p>
                                    ) : null}
                                    {data.followsPrice ? (
                                      <p>Follow: {data.followsPrice} PRPL </p>
                                    ) : null}
                                    {data.retweetsPrice ? (
                                      <p>Retweet: {data.retweetsPrice} PRPL </p>
                                    ) : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex text-prpl justify-content-center">
                                    <p>{data.totalPrice} PRPL</p>
                                  </div>
                                </td>
                              </tr>
                          }
                        </>
                      );
                    })
                    : ""}
                </tbody>
              </Table>
            </div>
          </Card.Body>
          <Card.Footer className="footer-items">
            <div className="d-felx justify-content-md-end justify-content-center">
              <>
                <InputGroup className="rows-per-page d-flex align-items-center justify-content-end justify-content-md-end justify-content-center">
                  <p>Rows per page : {limit}</p>
                  <DropdownButton
                    title=""
                    id="input-group-dropdown-1"
                    name="limit"
                    onSelect={handleSelect}
                  >
                    <Dropdown.Item eventKey="5"> 5</Dropdown.Item>
                    <Dropdown.Item eventKey="10"> 10</Dropdown.Item>
                    <Dropdown.Item eventKey="20"> 20</Dropdown.Item>
                    <Dropdown.Item eventKey="50"> 50</Dropdown.Item>
                    <Dropdown.Item eventKey="100"> 100</Dropdown.Item>
                  </DropdownButton>
                  <div className="d-flex ms-md-5 ms-2">
                    <p>
                      {paginations.page ? paginations.page : 0} -{" "}
                      {paginations.pages ? paginations.pages : 0} of{" "}
                      {paginations.total ? paginations.total : 0}
                    </p>
                  </div>
                  <div className="d-flex custom-pagination">
                    <button
                      disabled={currentPage === 1 ? true : ""}
                      onClick={prevPage}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="angle-icon"
                        icon={faAngleLeft}
                      />
                    </button>
                    <button
                      disabled={currentPage === totalPage ? true : ""}
                      onClick={nextPage}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="angle-icon"
                        icon={faAngleRight}
                      />{" "}
                    </button>
                  </div>
                </InputGroup>
              </>
            </div>
          </Card.Footer>
        </Card>
      )}
    </div>
  );
}
export default connect("", { RequestsList })(ActivePage);
