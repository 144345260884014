import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import { performerResToProof, RequestsDetailByID } from "../../../Redux/actions/requestActions"
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from "react-bootstrap";
import { Button, Badge } from 'react-bootstrap';
import { useParams, useHistory, Link } from "react-router-dom"
import { OnboardingButton } from "./../../../utils/metaMaskconnect"
import Twitter from "./../Twitter/twitter";
import { ENV } from "./../../../config/config"
import { ProgressBar } from "react-bootstrap"
import { toast } from "react-toastify";
import FullPageLoader from "../../FullPageLoader/fullPageLoader";
const serverUrl = ENV.serverUrl

function AcceptedRequests(props) {
    let history = useHistory();
    const { id } = useParams();
    const viewRequestDetail = useSelector(state => state?.gig.viewRequestDetail);
    const dispatch = useDispatch();
    let complete_percentage = " 0"
    const [errors, setErrors] = useState("")
    const [filter, setFilter] = useState("createdAt")
    const [avatar, setAvatar] = useState({ likeAvatar: "", likeAvatarName: "", followAvatar: "", followAvatarName: "", retweetAvatar: "", retweetAvatarName: "" })
    const count = useSelector(state => state?.gig.count);
    const [loader, setLoader] = useState(true)
    useEffect(() => {
        let token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            window.location.replace('/login')
        }
    }, [])
    useEffect(() => {
        dispatch(RequestsDetailByID(id))
    }, [count])
    // useEffect(() => {
    //     dispatch(RequestsDetailByID(id))
    // }, [])
    useEffect(() => {
        setLoader(false)
    }, [count])
    const onChangeImage = async (e) => {
        var avat;
        var avatName;
        avat = e.target.name + 'Avatar'
        avatName = e.target.name + 'AvatarName'
        const files = e.target.files;
        if (files) {
            setAvatar({ [avat]: files, [avatName]: files.name })
            return;
        }
    };


    // viewRequestDetail.gig._id, 1, "like", 1

    const onSubmit = (id, gigId, userId, proofRes, typeName, type) => {
        var typeVar;
        let flag = true
        typeVar = `${typeName}Status`
        if (type === 1 && viewRequestDetail.gig.likes === viewRequestDetail.gig.likesCount) {
            toast.error(" Maximum likes count reached")
            flag = false
        }
        else if (type == 2 && viewRequestDetail.gig.follows === viewRequestDetail.gig.followsCount) {
            toast.error("Maximum follow count reached")
            flag = false
        }
        else if (type == 3 && viewRequestDetail.gig.retweet === viewRequestDetail.gig.retweetsCount) {
            toast.error("Maximum retweets count reached")
            flag = false
        }
        if (flag) {
            let data = {
                id: id,
                gigId: gigId,
                status: proofRes,
                [typeVar]: proofRes,
                type: type,
                userId: userId
            }

            dispatch(performerResToProof(data))
        }
    }
    // const onSubmite = (type, typeName) => {
    //     var statusVar;
    //     var avatName;
    //     statusVar = typeName + 'Avatar'
    //     avatName = typeName + 'AvatarName'
    //     const myAvatar = avatar[[avat]]

    //     const avatNamee = avatar[[avatName]]


    //     var formData = new FormData();
    //     if (avatNamee === undefined || avatNamee === "") {
    //         toast.error("Please upload the image first!")
    //     } else {
    //         setErrors("")
    //         if (myAvatar === "") {
    //             formData.append("image", myAvatar);
    //         } else {
    //             formData.append("image", myAvatar, avatNamee);
    //         }
    //         formData.append("userId", "62060c676145c16094f2f501")
    //         formData.append("gigId", "620a0e9a2e21e869700bdc9d")
    //         formData.append("type", type)
    //         dispatch(uploadProof(formData))
    //     }
    // }


    {
        complete_percentage = Math.floor(
            (((viewRequestDetail?.gig?.likesCount ? viewRequestDetail.gig.likesCount : 0) +
                (viewRequestDetail?.gig?.retweetsCount ? viewRequestDetail.gig.retweetsCount : 0) +
                viewRequestDetail?.gig?.followsCount ? viewRequestDetail.gig.followsCount : 0) /
                (viewRequestDetail?.gig?.likes + viewRequestDetail?.gig?.retweet + viewRequestDetail?.gig?.follows)) *
            100
        )
    }
    return (
        <>
            {
                loader ? (
                    <FullPageLoader />
                ) : (

                    <div className="tabone-content active-page accepted-request">
                        <Row className="align-items-center mb-5">
                            <Col xl={6} className="order-lg-0 order-2" >
                                <div className="d-flex go-back back-btn-style align-items-sm-center">
                                    <button onClick={() => { history.goBack() }}>
                                        <FontAwesomeIcon className="me-2 faArrowLeft" icon={faArrowLeft} />
                                        Go back
                                    </button>
                                </div>
                            </Col>
                            <Col xl={6} className="mt-xl-0 mt-3 mb-xl-0 mb-3">
                                <div className="connect d-flex align-items-center">
                                    <OnboardingButton />
                                    <Twitter />
                                </div>
                            </Col>
                        </Row>
                        <Card>

                            <Card.Body>
                                <div className="table-responsive">
                                    <Table className="request-table tab-one-table" style={{ marginBottom: "0" }}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Request</th>
                                                <th className="text-center">Remaining</th>
                                                <th>Total</th>
                                                <th>Price per request</th>
                                                <th className="text-center">pprl rewards</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td></td>
                                                <td>
                                                    <div className="d-flex flex-column">
                                                        <div className="d-flex">
                                                            <div className="social-icons table-social-links">
                                                                <ul>
                                                                    <li>
                                                                        {
                                                                            viewRequestDetail?.gig?.likes ?
                                                                                <Link to="">
                                                                                    <FontAwesomeIcon icon={faHeart} />
                                                                                </Link>
                                                                                : null
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        {
                                                                            viewRequestDetail?.gig?.retweet ?
                                                                                <Link to="">
                                                                                    <FontAwesomeIcon icon={faRetweet} />
                                                                                </Link>
                                                                                : null}
                                                                    </li>
                                                                    <li>
                                                                        {
                                                                            viewRequestDetail?.gig?.follows ?
                                                                                <Link to="">
                                                                                    <FontAwesomeIcon icon={faUserPlus} />
                                                                                </Link>
                                                                                : null}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex next-page-info ">
                                                            <Link to={viewRequestDetail?.gig?.twitterUrl}>Tweet Url</Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="custome-de-pr">
                                                    <ProgressBar now={complete_percentage} label={`${complete_percentage}%`} />
                                                    <p className="text-center">{viewRequestDetail?.gig?.likes ? `Likes : ${viewRequestDetail?.gig?.likes - viewRequestDetail?.gig?.likesCount}` : null}  {viewRequestDetail?.gig?.follows ? `Follow : ${viewRequestDetail?.gig?.follows - viewRequestDetail.gig.followsCount}` : null}  {viewRequestDetail?.gig?.retweet ? `Retweets : ${viewRequestDetail?.gig?.retweet - viewRequestDetail.gig.retweetsCount}` : null}</p>
                                                </td>
                                                <td>    <p>{viewRequestDetail?.gig?.likes ? `Likes : ${viewRequestDetail?.gig?.likes}` : null} </p> <p>  {viewRequestDetail?.gig?.follows ? `Follow : ${viewRequestDetail?.gig?.follows}` : null} </p> <p> {viewRequestDetail?.gig?.retweet ? `Retweets : ${viewRequestDetail?.gig?.retweet}` : null}</p>
                                                </td>
                                                <td >
                                                    <div className="d-flex flex-column">
                                                        {viewRequestDetail?.gig?.likesPrice ? <p>Like: {viewRequestDetail?.gig?.likesPrice} PRPL</p> : null}
                                                        {viewRequestDetail?.gig?.followsPrice ? <p>Follow: {viewRequestDetail?.gig?.followsPrice} PRPL</p> : null}
                                                        {viewRequestDetail?.gig?.retweetsPrice ? <p>Retweet: {viewRequestDetail?.gig?.retweetsPrice} PRPL </p> : null}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex text-prpl justify-content-center">
                                                        <p>{viewRequestDetail?.gig?.totalPrice} PRPL</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            {viewRequestDetail?.gig?.likes ?
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="text-purple">
                                                        <p>Like</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="addres-box questioner-input-field">
                                                            <input value={viewRequestDetail?.gig?.twitterUrl} readOnly type="text" className="form-control form-control-lg" />
                                                        </div>
                                                    </td>
                                                    {(viewRequestDetail.likeStatus === 0 || viewRequestDetail.likeStatus === null) ?
                                                        <><td className="text-center">
                                                            <button className="btn btn-yellow" disabled={!viewRequestDetail.likeImage} onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 1, "like", 1) }}>Approve</button>
                                                        </td>
                                                            <td className="text-center">
                                                                <button className="btn btn-yellow" disabled={!viewRequestDetail.likeImage} onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 2, "like", 1) }}>Reject</button>
                                                            </td>



                                                        </>
                                                        : <td className="text-center">
                                                            {viewRequestDetail.likeStatus === 2 ?
                                                                <Badge bg="danger" > Rejected</Badge> :
                                                                <Badge bg="success" > Approved</Badge>}
                                                        </td>
                                                    }
                                                    <td>
                                                        {viewRequestDetail.likeImage ?
                                                            <a href={`${serverUrl + viewRequestDetail.likeImage}`} target="_blank" rel="noreferrer" >View Image</a>
                                                            : ""}
                                                    </td>

                                                </tr>
                                                : null}
                                            {viewRequestDetail?.gig?.follows ?
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="text-purple">
                                                        <p>Follow</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="addres-box questioner-input-field">
                                                            <input value={viewRequestDetail?.gig?.twitterUrl} readOnly type="text" className="form-control form-control-lg" />
                                                        </div>
                                                    </td>
                                                    {/* <td>


                                            <input type="file" id="file" name="follow" accept="image/*" onChange={onChangeImage} />
                                            <td className="text-center">
                                                <button className="btn btn-yellow" onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 1, "follow", 2) }}>Accept</button>
                                            </td>

                                        </td>
                                        <td className="text-center">
                                            <button className="btn btn-yellow" onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 2, "follow", 2) }}>Reject</button>
                                        </td> */}







                                                    {(viewRequestDetail.followStatus === 0 || viewRequestDetail.followStatus === null) ?
                                                        <><td className="text-center">
                                                            <button className="btn btn-yellow" disabled={!viewRequestDetail.followImage} onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 1, "follow", 2) }}>Approve</button>
                                                        </td>
                                                            <td className="text-center">
                                                                <button className="btn btn-yellow" disabled={!viewRequestDetail.followImage} onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 2, "follow", 2) }}>Reject</button>
                                                            </td>



                                                        </>
                                                        : <td className="text-center">
                                                            {viewRequestDetail.followStatus === 2 ?
                                                                <Badge bg="danger" > Rejected</Badge> :
                                                                <Badge bg="success" > Approved</Badge>}
                                                        </td>
                                                    }
                                                    <td>
                                                        {viewRequestDetail.followImage ?
                                                            <a href={`${serverUrl + viewRequestDetail.followImage}`} target="_blank" rel="noreferrer" >View Image</a>
                                                            : ""}
                                                    </td>
                                                </tr>
                                                : null}
                                            {viewRequestDetail?.gig?.retweet ?
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td className="text-purple">
                                                        <p>Retweet</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="addres-box questioner-input-field">
                                                            <input value={viewRequestDetail?.gig?.twitterUrl} readOnly type="text" className="form-control form-control-lg" />
                                                        </div>
                                                    </td>
                                                    {/* 
                                        <td className="text-center">
                                            <button className="btn btn-yellow" onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 1, "retweet", 3) }}>Accept</button>
                                        </td>
                                        <td className="text-center">
                                            <button className="btn btn-yellow" onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 2, "retweet", 3) }}>Reject</button>
                                        </td> */}


                                                    {(viewRequestDetail.retweetStatus === 0 || viewRequestDetail.retweetStatus === null) ?
                                                        <><td className="text-center">
                                                            <button className="btn btn-yellow" disabled={!viewRequestDetail.retweetImage} onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 1, "retweet", 3) }}>Approve</button>
                                                        </td>
                                                            <td className="text-center">
                                                                <button className="btn btn-yellow" disabled={!viewRequestDetail.retweetImage} onClick={(e) => { onSubmit(viewRequestDetail._id, viewRequestDetail.gigId, viewRequestDetail.userId, 2, "retweet", 3) }}>Reject</button>
                                                            </td>



                                                        </>
                                                        : <td className="text-center">
                                                            {viewRequestDetail.retweetStatus === 2 ?
                                                                <Badge bg="danger" > Rejected</Badge> :
                                                                <Badge bg="success" > Approved</Badge>}
                                                        </td>
                                                    }
                                                    <td>
                                                        {viewRequestDetail.retweetImage ?
                                                            <a href={`${serverUrl + viewRequestDetail.retweetImage}`} target="_blank" rel="noreferrer" >View Image</a>
                                                            : ""}
                                                    </td>
                                                </tr>
                                                : null}

                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </div >
                )}
        </>
    );
}
export default AcceptedRequests;
