import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Banermobile from "../../../assets/images/banner-right.png";

function MainBanner(props) {
    return (
        <section id="site-banner" className="main-banner">
            <Container fluid className="container-width">
                <Row className="">
                    <Col lg={6}>
                        <div className="banner-content d-flex flex-column">
                            {/* <h1>Drive</h1> */}
                            <h2>Twitter Shilling</h2>
                            <h4>ACCELERATE YOUR TWITTER SUCCESS</h4>
                            <p>Leave the competition in the dust with our targeted Twitter promotion! Boost your followers, uplift engagement, and drive more sales.</p>
                            <div className="link-d">
                                <Link className="btn-yellow" to="/request">Launch App</Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-lg-start justify-content-center">
                        <div className="baner-image">
                            <img className="img-fluid" src={Banermobile} alt=" Site Logo" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
export default MainBanner;
