import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Followers from "../../../assets/images/get-real.png";
import { Link } from "react-router-dom";
import Icon from "../../../assets/images/engaged-icon.svg";
import Frame1 from "../../../assets/images/frame1.png";
import Frame2 from "../../../assets/images/frame2.png";
import Frame3 from "../../../assets/images/frame3.png";
import Frame4 from "../../../assets/images/frame4.png";
import Frame5 from "../../../assets/images/frame5.png";
function Engaged() {

    return (
        <section className="engaged-followers">
            <div className="bg-animation">
                <div className="image frame1">
                    <img className="img-fluid" src={Frame1} alt=" Site Logo" />
                </div>
                <div className="image frame2">
                    <img className="img-fluid" src={Frame2} alt=" Site Logo" />
                </div>
                <div className="image frame3">
                    <img className="img-fluid" src={Frame3} alt=" Site Logo" />
                </div>
                <div className="image frame4">
                    <img className="img-fluid" src={Frame4} alt=" Site Logo" />
                </div>
                <div className="image frame5">
                    <img className="img-fluid" src={Frame5} alt=" Site Logo" />
                </div>
            </div>
            <Container fluid className="container-width">
                <Row className="">
                    <Col lg={6}>
                        <div className="section-content">
                            <div className="icon">
                                <img className="img-fluid" src={Icon} alt=" Site Logo" />
                            </div>
                            <h3>Gain Real, Organic, and Targeted Twitter Followers </h3>
                            <p>Are you tired of endlessly scrolling through Twitter, only to be left unsatisfied with the results for your business or personal brand? Well, it's high time to put an end to that. Instead of spending countless hours on Twitter, invest just a few minutes and let others do the work for you. With Twitter Shilling, you can easily create requests for likes, follows, and retweets of the posts that interest you, ensuring that you're building an engaged and relevant audience.</p>
                            <p>Plus, the best part is that all this can be achieved with minimal effort from your side. And the benefits? They're limitless! Expect an uptick in sales, clients, and subscribers as your brand grows and gets noticed on Twitter.</p>
                            <p className="text-blue fw-600">Let’s start unlocking the potential of your Twitter account today!</p>
                            <Link to="/request" className="btn btn-yellow">Join As Requester</Link>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="image">
                            <img className="img-fluid" src={Followers} alt=" Site Logo" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
export default Engaged;
