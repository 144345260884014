import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "@fontsource/noto-sans-bengali";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import Login from "./components/Login/Login";
import UserFront from "./components/UserRoutes/routesBody";
class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="*" name="FrontMain Page" component={UserFront} />
        </Switch>
      </Router>
    );
  }
}

export default App;
