import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon1 from "../../../assets/images/recycle.png";
import Icon2 from "../../../assets/images/add-user.png";
import Icon3 from "../../../assets/images/heart.png";

function Performing() {

    return (
        <section className="start-performing">
            <Container fluid className="container-width">
                <div className="section-content">
                    <h3>Let's KickStart To Earn PRPL</h3>
                    <p>You can start performing tasks and achieving goals to earn PRPL tokens by participating in Twitter Shilling. These tokens represent a tangible reward for enhancing your online presence and increasing your reach. What's more, you'll contribute to other Twitter users' success while you do it!</p>
                    <div className="action-btns-list">
                        <Link to="/request" className="btn btn-yellow">Launch App</Link>
                        {/* <Link to="/" className="btn btn-white-border">Contact Support</Link> */}
                    </div>
                </div>
                <div className="icon recycle">
                    <img src={Icon1} alt="Recycle" className="img-fluid" />
                </div>
                <div className="icon add-user">
                    <img src={Icon2} alt="Recycle" className="img-fluid" />
                </div>
                <div className="icon heart">
                    <img src={Icon3} alt="Recycle" className="img-fluid" />
                </div>
            </Container>
        </section>
    );
}
export default Performing;
