import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import "@fontsource/noto-sans-bengali";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import RequstDetails from "../RequestPage/ActivePage/RequstDetails.js";
import Dashborad from "../Dashborad/Dashborad.js";
import RequestAction from "../RequestPage/Request/RequestAction";
import ActiveRequest from "../RequestPage/ActivePage/ActivePage";
import AcceptedRequests from "../RequestPage/AcceptedRequests/AcceptedRequests.js";
import StakingPage from "../RequestPage/staking/StakingPage.js";
import MakeRequest from "../RequestPage/MakeRequest/MakeRequest.js";
import YourRequest from "../RequestPage/Request/YourRequest.js";
import RequestModel from "../RequestPage/MakeRequest/RequestModel.js";
import AcceptedRequestDetails from "../RequestPage/AcceptedRequests/accepted-request-detail";
import ClaimToken from "../RequestPage/ClaimTokens/ClaimList";
import Login from "../Login/Login"
function userFront() {
  return (
    <section id="request">
      <div className="container-fluid container-width">
        <div className="active-tab-section">
          {/* <DashboradHeader /> */}
          <Switch>
            {/* <Route exact path="" name="Request" component={ActiveRequest} /> */}
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/request"
              name="Active Request"
              component={ActiveRequest}
            />
            <Route
              exact
              path="/accepted-request"
              name="Accepted Request"
              component={AcceptedRequests}
            />
            <Route
              exact
              path="/claim"
              name="Staking"
              component={StakingPage}
            />
            <Route
              exact
              path="/make-request"
              name="Make Request"
              component={MakeRequest}
            />
            <Route
              exact
              path="/my-request"
              name="My Request"
              component={YourRequest}
            />
            <Route
              exact
              path="/request-view/:id"
              name="RequstDetails"
              component={RequstDetails}
            />
            <Route
              exact
              path="/prpl-dashborad/:id"
              name="Request"
              component={Dashborad}
            />
            <Route
              exact
              path="/request-action/:id"
              name="Request Action"
              component={RequestAction}
            />
            <Route
              exact
              path="/request-model"
              name="Request Model"
              component={RequestModel}
            />
            <Route
              exact
              path="/accepted-request-details/:id"
              name="Accepted Request Details"
              component={AcceptedRequestDetails}
            />
            <Route
              exact
              path="/claim-token"
              name="Claim Token"
              component={ClaimToken}
            />
          </Switch>
        </div>
      </div>
    </section>
  );
}
export default withRouter(userFront);
