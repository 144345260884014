import { SET_CURRENT_USER, USER_LOADING, REGISTER_USER, UPDATE_CURRENT_USER, GET_USERS } from "../actions/actionType";
const initialState = {
    isAuthenticated: false,
    user: {},
    token: "",
    loading: false, userData:[]
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token
            };
        case UPDATE_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload?.user,
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true
            };
        case REGISTER_USER:
            return {
                ...state,
                user:action.payload,
                loading: true
            };
        case GET_USERS:
            
            console.log(action.payload);
            return {
                ...state,
                userData: action.payload

            }
        default:
            return state;
    }
}