import React, { useState, useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Container, Row, Col, Form } from "react-bootstrap";
import twitter from "../../assets/images/smalltwitter.jpg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Twitter from "./../RequestPage/Twitter/twitter"
import { Link, withRouter } from "react-router-dom"
function Login() {
    return (
        <section className="contact-us login-pg">
            <Container fluid className="container-width">
                <Row className="no-gutters justify-content-center align-items-center">
                    <Col xl={7}>
                        <div className="contact-image">
                            <img className="img-fluid" src={twitter} alt=" Site Logo" />
                        </div>
                    </Col>
                    <Col xl={5}>
                        <div className="content-area">
                            <div className="d-flex mb-md-5 mb-2">
                                <h2 className="title">Login</h2>
                            </div>
                            <div className=" mb-3">
                                <Twitter />
                                {/* <Form
                                    action=""
                                    className="form"
                                    method=""
                                    onSubmit={handleSubmit(submit)}
                                >
                                    <div className="form-group ">
                                        <FloatingLabel controlId="floatingInput" label="Enter your email" className="mb-3">
                                            <Form.Control
                                                name="email"
                                                type="text"
                                                {...register("email")}
                                                className={`form-control ${errors.email ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Enter your email" />
                                            <div className="invalid-feedback">
                                                {errors.email?.message}
                                            </div>
                                        </FloatingLabel>
                                    </div>
                                    <div className="form-row">
                                        <FloatingLabel controlId="floatingInput" label="Enter Password" className="mb-3">
                                            <Form.Control
                                                name="password"
                                                type="password"
                                                {...register("password")}
                                                className={`form-control ${errors.password ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Enter Password" />
                                            <div className="invalid-feedback">
                                                {errors.password?.message}
                                            </div>
                                        </FloatingLabel>
                                    </div>

                                    <div className="d-flex mt-4">
                                        <button className="boder-btn sigunp-btn btn">Sign in</button>
                                    </div>
                                </Form> */}
                                {/* <div className="d-flex agreement-detail">
                                    <p>
                                        Don't have an account?
                                        <a>Creat Your Account</a>
                                        it takes less than a minute
                                    </p>
                                </div> */}

                            </div>
                        </div>
                    </Col>
                </Row >
            </Container >
        </section >
    );
}
export default Login;