import {
  SET_REQUEST_DATA,
  REQUESTS_LIST,
  DELETE_GIG,
  SET_CURRENT_USER,
  REQUESTS_LIST_ID,
  VIEW_GIG,
  ACCEPT_GIG,
  SET_PROOF,
  UPDATE_PROOF,
  REQUESTS_DETAIL_BY_ID,
  SET_PROOF_ID,
  CLAIM_TOKEN,
  CLAIM_TOKEN_REQUEST
} from "../actions/actionType";
const initialState = {
  isAuthenticated: false,
  closeModel: false,
  gigs: [],
  gig: [],
  viewGig: [],
  accepted: [],
  accpectedGigs: [],
  pagination: [],
  viewAcceptedGig: [],
  viewRequestDetail: [],
  count: 0,
  acceptedGigbyId: [],
  user: [],
  claimrequest: [],
  claimList: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REQUEST_DATA:
      return {
        ...state,
        closeModel: true,
        newGig: action.payload,
      };
    case UPDATE_PROOF:
      return {
        ...state,
        count: (state.count += 1),
      };
    case REQUESTS_LIST:
      return {
        ...state,
        closeModel: true,
        gigs: action.payload.data.gigs,
        pagination: action.payload.data.pagination,
      };
    case SET_PROOF:
      return {
        ...state,
        accpectedGigs: action.payload.gigs,
        pagination: action.payload.pagination,
      };
    case SET_PROOF_ID:
      return {
        ...state,
        acceptedGigbyId: action.payload.gig[0],
      };
    case DELETE_GIG:
      return {
        ...state,
        gigs: action.payload,
      };
    case REQUESTS_LIST_ID:
      return {
        ...state,
        gig: action.payload.data.gigs,
        pagination: action.payload.data.pagination,
      };
    case VIEW_GIG:
      return {
        ...state,
        viewGig: action.payload.gig,
        viewAcceptedGig: action.payload.gig[0].gigsaccepted,
      };
    case ACCEPT_GIG:
      return {
        ...state,
        accepted: action.payload,
      };
    case REQUESTS_DETAIL_BY_ID:
      return {
        ...state,
        viewRequestDetail: action.payload.data.gig[0],
      };
    case SET_CURRENT_USER:
      return {
        user: action.payload,
      };
    case CLAIM_TOKEN:
      return {
        ...state,
        claimrequest: action.payload,
      };
      case CLAIM_TOKEN_REQUEST :
        return{
          ...state,
          claimList: action.payload.data.requests,
          pagination: action.payload.data.pagination,
        }
    default:
      return state;
  }
}
