import axios from "axios";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  REGISTER_USER,
  UPDATE_CURRENT_USER,
  GET_USERS,
} from "./actionType";
import { apiHelper } from "./apiHelper";
// const baseUrl = process.env.REACT_APP_SERVER_URL
import { ENV } from "./../../config/config";
import { toast } from "react-toastify";
const baseUrl = ENV.serverUrl;
export const registerUser = (userData) => async (dispatch) => {
  let res = await apiHelper("post", `user`, userData);
  try {
    if (res) {
      let data = await res.json();
      if (data.success) {
        dispatch({
          type: REGISTER_USER,
          payload: data,
        });
      } else {
        toast.error(data.message);
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload: data,
    });
  }
};

// Set logged in user
export const setCurrentUser = (data) => (dispatch) => {
  // localStorage.setItem("user", JSON.stringify(data.user))
  let x = ENV.encryptUserData(data.user);

  return {
    type: SET_CURRENT_USER,
    payload: data,
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};
export const setUserType = () => {
  return {
    type: USER_LOADING,
  };
};
export const getUser = (id) => async (dispatch) => {
  let res = await apiHelper("get", `v1/front/users/${id}`);
  try {
    if (res) {
      let { data } = res;
      if (data.status) {
        dispatch({
          type: GET_USERS,
          payload: data,
        });
      } else {
        toast.error(data.message);
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload: data,
    });
  }
};

// update User Type
export const updateUserType = (data) => async (dispatch) => {
  let res = await apiHelper("put", `v1/front/users`, data);
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        toast.success(data.message);

        // localStorage.setItem("user", JSON.stringify(data.data))
        ENV.encryptUserData(data.data);
        dispatch({
          type: UPDATE_CURRENT_USER,
          payload: data.newGig,
        });
      }
    }
  } catch (err) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload: data.newGig,
    });
  }
};
