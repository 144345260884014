import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { ClaimRequest } from "../../../Redux/actions/requestActions";
import { useDispatch, useSelector } from "react-redux";
import DashboradHeader from "../../DashboardHeader/DashboardHeader";
import { Badge } from "react-bootstrap";
import { ENV } from "../../../config/config";
import { getUser } from "../../../Redux/actions/authActions";
import moment from "moment";
import FullPageLoader from "../../FullPageLoader/fullPageLoader";
const serverUrl = ENV.serverUrl;
function AcceptedRequests(props) {
  const user = ENV.getUserKeys();
  const WalletAddress = localStorage.getItem("WalletAddress");
  const count = useSelector((state) => state?.gig.count);
  const accpectedGigs = useSelector((state) => state?.gig.claimList);
  const paginations = useSelector((state) => state?.gig.pagination);
  const userId = user?.data._id;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState("1");
  const [totalPage, setTotalPage] = useState(null);
  const [limit, setLimit] = useState("10");
  const [filter, setFilter] = useState("createdAt");
  const [loader, setLoader] = useState(true)
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      window.location.replace("/login");
    }
  }, []);
  useEffect(() => {
    dispatch(getUser(user.data?._id));
  }, []);
  useEffect(() => {
    dispatch(ClaimRequest(userId, currentPage, limit, filter));
  }, [count]);
  useEffect(() => {
    setCurrentPage(paginations.page);
    setTotalPage(paginations.pages);
    setLoader(false)
  }, [accpectedGigs]);

  const handleSelect = (e) => {
    setLimit(e);
    dispatch(ClaimRequest(userId, 1, e, filter));
  };
  const prevPage = () => {
    setCurrentPage(currentPage - 1);

    if (currentPage >= 1) {
      dispatch(ClaimRequest(userId, currentPage - 1, limit, filter));
    }
  };
  const nextPage = () => {
    if (currentPage >= 1 && currentPage < totalPage) {
      dispatch(ClaimRequest(userId, currentPage + 1, limit, filter));
    }
  };
  const FilterData = (e) => {
    setFilter(e.target.value);
    dispatch(ClaimRequest(userId, currentPage, limit, e.target.value));
  };

  //   const claimPRPL = async () => {
  //     let WalletAddress = await localStorage.getItem("WalletAddress");

  //     if (WalletAddress) {
  //       await ClaimPrpl(user?.data?.rewards);
  //     } else {
  //       MetaMaskConnection();
  //     }
  //   };
  console.log(accpectedGigs);
  return (
    <div className="tabone-content active-page accepted-request">
      <DashboradHeader />
      {loader ? (<FullPageLoader />) :
        (
          <Card>
            <Card.Body>
              <div className="table-responsive">
                <Table
                  className="request-table tab-one-table"
                  style={{ marginBottom: "0" }}
                >
                  <thead>
                    <tr>
                      <th className="text-center">Wallet Address</th>
                      <th className="text-center">Amount</th>
                      <th className="text-center">Created At</th>
                      <th className="text-center">Status</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accpectedGigs && accpectedGigs.length > 0
                      ? accpectedGigs.map((data, key) => {
                        console.log(data);
                        return (
                          <>
                            <tr key={key}>
                              <td className="text-center">
                                <p> {data?.wallet_address} </p>
                              </td>
                              <td className="text-center">
                                <p> {data?.amount} PRPL </p>
                              </td>
                              <td className="text-center">
                                <p>
                                  {moment(data?.createdAt).format("DD MMM YYYY")}{" "}
                                </p>
                              </td>
                              <td className="text-center">
                                {data?.status === 1 ? (
                                  <Badge className="status-bg alert-success">Approved</Badge>
                                ) : (
                                  ""
                                )}
                                {data?.status === 2 ? (
                                  <Badge className="status-bg alert-danger">Rejected</Badge>
                                ) : (
                                  ""
                                )}
                                {data?.status === 0 ? (
                                  <Badge className="status-bg alert-info">Pending</Badge>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })
                      : ""}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer className="footer-items">
              <div className="d-felx justify-content-md-end justify-content-center">
                <>
                  <InputGroup className="rows-per-page d-flex align-items-center justify-content-end justify-content-md-end justify-content-center">
                    <p>Rows per page : {limit}</p>
                    <DropdownButton
                      title=""
                      id="input-group-dropdown-1"
                      name="limit"
                      onSelect={handleSelect}
                    >
                      <Dropdown.Item eventKey="5"> 5</Dropdown.Item>
                      <Dropdown.Item eventKey="10"> 10</Dropdown.Item>
                      <Dropdown.Item eventKey="20"> 20</Dropdown.Item>
                      <Dropdown.Item eventKey="50"> 50</Dropdown.Item>
                      <Dropdown.Item eventKey="100"> 100</Dropdown.Item>
                    </DropdownButton>
                    <div className="d-flex ms-md-5 ms-2">
                      <p>
                        {paginations.page ? paginations.page : 0} -{" "}
                        {paginations.pages ? paginations.pages : 0} of{" "}
                        {paginations.total ? paginations.total : 0}
                      </p>
                    </div>
                    <div className="d-flex custom-pagination">
                      <button
                        disabled={currentPage === 1 ? true : ""}
                        onClick={prevPage}
                      >
                        {" "}
                        <FontAwesomeIcon
                          className="angle-icon"
                          icon={faAngleLeft}
                        />
                      </button>
                      <button
                        disabled={currentPage === totalPage ? true : ""}
                        onClick={nextPage}
                      >
                        {" "}
                        <FontAwesomeIcon
                          className="angle-icon"
                          icon={faAngleRight}
                        />{" "}
                      </button>
                    </div>
                  </InputGroup>
                </>
              </div>
            </Card.Footer>
          </Card>
        )
      }
    </div>
  );
}
export default AcceptedRequests;
