import Web3 from "web3";
import contractAbi from "./abi/prplabi.json";
import { toast } from "react-toastify";
import Store from "../Redux/Reducers/combineReducers";
import { depositTranscations } from "../Redux/actions/Web3Action";
var BigNumber = require("big-number");
const ContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
export const getWeb3 = async () => {
  if (window.ethereum) {
    const web3 = new Web3(Web3.givenProvider);
    return web3;
  } else {
    return false;
  }
};

export const Approve = async (amount) => {
  const web3 = await getWeb3();
  try {
    let contract = new web3.eth.Contract(contractAbi, ContractAddress);
    const accounts = await web3.eth.getAccounts();
    // let amountVal = BigNumber(amount).multiply(1000000000000000000);
    let amountVal = web3.utils.toWei(amount.toString(), 'ether')
    let hash = await contract.methods
      .approve(ContractAddress, amountVal)
      .send({ from: accounts[0] });
    const transaction = hash;
    if (transaction.transactionHash) {
      var receipt = await web3.eth.getTransactionReceipt(
        transaction.transactionHash
      );
    }
    return receipt.status;
  } catch (e) {
    return false;
  }
};

export const Transfer = async (amount, userId) => {
  const web3 = await getWeb3();
  try {
    let contract = new web3.eth.Contract(contractAbi, ContractAddress);
    const accounts = await web3.eth.getAccounts();
    // let amountVal = BigNumber(amount).multiply(1000000000000000000);
    let amountVal = web3.utils.toWei(amount.toString(), 'ether')
    let hash = await contract.methods
      .transfer(ContractAddress, amountVal)
      .send({ from: accounts[0] });
    const txHash = hash.transactionHash;
    const address = accounts[0];
    const payload = {
      type: "2",
      amount,
      address,
      txHash,
      network: "1",
      userId,
    };
    Store.dispatch(depositTranscations([payload]));
    var receipt = await web3.eth.getTransactionReceipt(txHash);
    return receipt;
  } catch (e) {
    return false;
  }
};

export const ClaimPrpl = async (amount) => {
  const web3 = await getWeb3();
  try {
    let contract = new web3.eth.Contract(contractAbi, ContractAddress);
    const accounts = await web3.eth.getAccounts();
    // let amountVal = BigNumber(amount).multiply(1000000000000000000);
    let amountVal = web3.utils.toWei(amount.toString(), 'ether')
    let hash = await contract.methods
      .claim(accounts[0], amountVal)
      .send({ from: accounts[0] });
    const transaction = hash.transactionHash;
    var receipt = await web3.eth.getTransactionReceipt(transaction);
    return receipt.status;
  } catch (e) {
    return false;
  }
};
