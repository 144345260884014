import React, { useState, useEffect } from "react";
import TwitterLogin from "react-twitter-auth";
import { setCurrentUser } from "../../../Redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ENV } from "../../../config/config";
import { Dropdown } from "react-bootstrap";
// setCurrentUser
function Twitter(props) {
  const user = ENV.getUserKeys("");
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.auth.user);
  const history = useHistory();
  const [state, setState] = useState({
    isAuthenticated: false,
    token: "",
    user: null,
  });

  const logout = () => {
    ENV.logout();
    history.push("/login");
  };

  const onFailure = (error) => {};

  useEffect(() => {}, [userInfo]);
  const twitterResponse = (response) => {
    const token = response.headers.get("x-auth-token");
    response.json().then((user) => {
      localStorage.setItem("token", JSON.stringify(user.token));
      setState({ isAuthenticated: true, user: user, token });
      const data = {
        token: token,
        user: user,
      };
      dispatch(setCurrentUser(data));
      ENV.encryptUserData(user.user);
      // localStorage.setItem("user", JSON.stringify(user.user))
      history.push({
        pathname: "/request",
      });
    });
  };

  let content = !!state.isAuthenticated ? (
    <div>
      <p className="connect-btn"> {state.user?.username?.username}</p>
    </div>
  ) : (
    <div>
      {!user?.data?.username ? (
        <TwitterLogin
          loginUrl={`${process.env.REACT_APP_SERVER_URL}v1/front/auth/twitter/login`}
          onFailure={onFailure}
          onSuccess={twitterResponse}
          requestTokenUrl={`${process.env.REACT_APP_SERVER_URL}v1/front/auth/twitter/requestToken`}
          className="btn btn-yellow"
        />
      ) : (
        <Dropdown>
          <Dropdown.Toggle className="btn btn-yellow" id="dropdown-basic">
            {user?.data?.username}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item> */}
            <Dropdown.Item onClick={logout}>LogOut</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );

  return <div className="App">{content}</div>;
}

export default Twitter;
