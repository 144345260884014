import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import RequestModel from "./RequestModel";
import { Link, useHistory } from "react-router-dom"
import DashboradHeader from '../../DashboardHeader/DashboardHeader'
import { ENV } from "./../../../config/config"
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
function MakeRequest(props) {
	const user = ENV.getUserKeys()
	const history = useHistory()
	const today = moment().format('YYYY-MM-DD');

	const [requestData, setRequestData] = useState({
		userId: user.data?._id,username:user.data?.username, follows: 0, followsPrice: 0, likes: 0, likesPrice: 0, retweet: 0, retweetsPrice: 0, twitterUrl: "", activationDate: "", expirationDate: "", isPaid: true, txHash
			: ""
	})
	const [show, setShow] = useState(false)
	const [data, setData] = useState(null)

	const [errors, setErrors] = useState("")
	useEffect(() => {
		let token = JSON.parse(localStorage.getItem('token'));
		if (!token) {
			window.location.replace('/login')
		}
	}, [])
	const onChange = (e) => {
		let { name, value } = e.target
		let data = requestData
		data[name] = value
		setRequestData({ ...data })
	}
	const onSubmitHandler = async (e) => {

		let date = moment(new Date()).format("YYYY MM DD")
		e.preventDefault()
		const twitterRegex = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
		const numRegex = /[1-9]/
		const { follows, followsPrice, likes, likesPrice, retweet, retweetsPrice, twitterUrl, activationDate, expirationDate } = requestData
		// if (follows && followsPrice && likes && likesPrice && retweets && retweetsPrice && twitterUrl) {
		let count = 0
		let errorMessage = '';
		let isError = false;
		let activationDates = moment(activationDate).format("YYYY MM DD")
		if (follows === 0 && followsPrice === 0 && likes === 0 && likesPrice === 0 && retweet === 0 && retweetsPrice === 0 && !isError) {
			errorMessage = "You have to select atleast one package";
			isError = true;
		} else {
			if (!twitterRegex.test(twitterUrl) || twitterUrl === "" && !isError) {
				errorMessage = "Please enter correct  post URL!";
				isError = true;
			} else {
				let date1=moment(activationDate);
				let date2=moment(expirationDate);
				if ((activationDate === "" || expirationDate === "") && !isError) {
					errorMessage = " You have to select the activation date and expiration date";
					isError = true;
				}
				else if (activationDates < date && !isError) {
					errorMessage = "Activation date should not previous date!";
					isError = true;
				}
				else if (date1.isSame(date2) || date1.isAfter(date2) && !isError) {
					debugger
					errorMessage = "select the correct expire date it should not to equal to activation date or not previous date!";
					isError = true;
				}
				else {
					count = count + 1
					errorMessage = "";
				}
				if ((follows !== 0 || followsPrice !== 0) && !isError) {
					if ((follows <= 0 || !numRegex.test(follows)) && !isError) {
						errorMessage = "Follow should be a valid number greater than zero!";
						isError = true;
					} else if ((followsPrice <= 0 || !numRegex.test(followsPrice)) && !isError) {
						errorMessage = "Follow price should be a valid number greater than zero!";
						isError = true;
					} else {
						count = count + 1
						errorMessage = "";
					}
				}
				if ((retweet !== 0 || retweetsPrice !== 0) && !isError) {
					if ((retweet <= 0 || !numRegex.test(retweet)) && !isError) {
						errorMessage = "Retweets should be a valid number greater than zero!";
						isError = true;
					} else if ((retweetsPrice <= 0 || !numRegex.test(retweetsPrice)) && !isError) {
						errorMessage = "Retweets price should be a valid number greater than zero!";
						isError = true;
					} else {
						count = count + 1
						errorMessage = "";
					}
				}
				if ((likes !== 0 || likesPrice !== 0) && !isError) {
					if ((likes <= 0 || !numRegex.test(likes)) && !isError) {
						errorMessage = "Likes should be a valid number greater than zero!";
						isError = true;
					} else if ((likesPrice <= 0 || !numRegex.test(likesPrice)) && !isError) {
						errorMessage = "Likes price should be a valid number greater than zero!";
						isError = true;
					} else {
						count = count + 1
						errorMessage = "";
					}
				}

			}

		}
		// alert(errorMessage)
		if (count > 0 && !isError) {

			setShow(true)
			setErrors("")
		}
		else {
			setErrors(errorMessage)
		}
	}
	let justCloseModel = (value) => {
		if (!value) {
			setShow(value)
		}
	}
	let setModelShow = (value) => {

		if (!value) {
			setShow(value)
			setRequestData({ userId: user?._id, follows: 0, followsPrice: 0, likes: 0, likesPrice: 0, retweet: 0, retweetsPrice: 0, twitterUrl: "" })
		}
	}
	return (
		<div className="tabone-content">
			<DashboradHeader />
			<Card>
				<Card.Body className="make-request-tab">
					<div className="">
						<div className="d-flex flex-sm-row flex-column justify-content-sm-between align-items-sm-center">
							<div className="d-flex">
								<h2>Make Request</h2>
							</div>
							{/* <div className="d-flex align-items-sm-center justify-content-end">
								<Link to="" className="learn-more2">
									Learn more
									<FontAwesomeIcon
										className="filter-color"
										icon={faExternalLinkAlt}
									/>
								</Link>
							</div> */}
						</div>
						<div className="d-flex how-does-work flex-column">
							<h3>How does this work? </h3>
							<h3>
								Choose to request a Like, Follow, or Retweet of a given Twitter
								post and/or Twitter account.
							</h3>
							<ol>
								<li>
									Choose your type of Request below (you can select multiple to
									be sent in one request)
								</li>
								<li>Choose your price</li>
								<li>Paste your link(s)</li>
								<li>Submit transaction </li>
								<li>Wait</li>
							</ol>
							<p className="learn-how">
								The PRPL protocol has thousands of Performers waiting to act on
								your requests! Start making a request now....
							</p>
							<p className="learn-how style-text">
								Learn how to become a Performer & start earning PRPL today!
							</p>
						</div>
						<div className="bg-gray-box">
							<Row className="align-items-center make-question ">
								<Col xl={4}>
									<div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center style-fields">
										<div className="d-flex">
											<p>How many Follow do you want the post to have?</p>
										</div>
										<div className="d-flex questioner-input-field">
											<input
												name="follows"
												value={requestData.follows}
												onChange={onChange}
												type="number"
												className="form-control form-control-lg"
											/>
										</div>
									</div>
								</Col>
								<Col xl={4}>
									<div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center style-fields">
										<div className="d-flex">
											<p>How many Retweets do you want the post to have?</p>
										</div>
										<div className="d-flex questioner-input-field">
											<input
												type="number"
												name="retweet"
												value={requestData.retweet}
												onChange={onChange}
												className="form-control form-control-lg"
											/>
										</div>
									</div>
								</Col>
								<Col xl={4}>
									<div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center style-fields">
										<div className="d-flex">
											<p>How many Likes do you want the post to have?</p>
										</div>
										<div className="d-flex questioner-input-field">
											<input
												name="likes"
												value={requestData.likes}
												onChange={onChange}
												type="number"
												className="form-control form-control-lg"
											/>
										</div>
									</div>
								</Col>

							</Row>
							<Row className="align-items-center make-question">
								<Col xl={4}>
									<div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center style-fields">
										<div className="d-flex">
											<p>How much you want to pay for Follows?</p>
										</div>
										<div className="d-flex questioner-input-field">
											<input
												name="followsPrice"
												value={requestData.followsPrice}
												onChange={onChange}
												type="number"
												className="form-control form-control-lg"
											/>
										</div>
									</div>
								</Col>
								<Col xl={4}>
									<div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center style-fields">
										<div className="d-flex">
											<p>How much you want to pay for Retweets?</p>
										</div>
										<div className="d-flex questioner-input-field">
											<input
												name="retweetsPrice"
												value={requestData.retweetsPrice}
												onChange={onChange}
												type="number"
												className="form-control form-control-lg"
											/>
										</div>
									</div>
								</Col>
								<Col xl={4}>
									<div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center style-fields">
										<div className="d-flex">
											<p>How much you want to pay for Likes?</p>
										</div>
										<div className="d-flex questioner-input-field">
											<input
												name="likesPrice"
												value={requestData.likesPrice}
												onChange={onChange}
												type="number"
												className="form-control form-control-lg"
											/>
										</div>
									</div>
								</Col>

							</Row>

							<Row className="align-items-center make-question justify-content-center">
								<Col sm={12}>
									<div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center style-fields">
										<div className="d-flex">
											<p>Enter the URL of the Twitter post to request for:</p>
										</div>
										<div className="d-flex questioner-input-field custom-w">
											<input
												name="twitterUrl"
												value={requestData.twitterUrl}
												onChange={onChange}
												type="text"
												className="form-control form-control-lg"
											/>
										</div>
									</div>
								</Col>
							</Row>
							<Row className="align-items-center make-question justify-content-center">
								<Col xl={6}>
									<div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center style-fields">
										<div className="d-flex">
											<p>Select Start Date?</p>
										</div>
										<div className="d-flex questioner-input-field custom-w">
											<input
												type="date"
												name="activationDate"
												onChange={onChange}
												min={today}
												value={requestData.activationDate}
												className="form-control form-control-lg"/>
										</div>
									</div>
								</Col>
								<Col xl={6}>
									<div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center style-fields">
										<div className="d-flex">
											<p className="text-xl-end">Select End Date?</p>
										</div>
										<div className="d-flex questioner-input-field custom-w">
											<input
												type="date"
												name="expirationDate"
												onChange={onChange}
												min={today}
												value={requestData.expirationDate}
												className="form-control form-control-lg" />
										</div>
									</div>
								</Col>
							</Row>
							{errors ? (
								<div
									style={{ color: "#FE6E00" }}
									className="alert bg-danger text-white"
								>
									{errors}
								</div>
							) : (
								""
							)}
							<div className="d-flex flex-fill justify-content-center">

								<button className="btn-yellow make-request-btn2 btn mt-3" onClick={(e) => onSubmitHandler(e)}>
									Make Request
								</button>
								<RequestModel show={show} justCloseModel={justCloseModel} setShowValue={setModelShow} requestData={data ? data : requestData} />
							</div>
						</div>
					</div>
				</Card.Body>
				{/* <Card.Footer className=" d-flex footer-items justify-content-md-end justify-content-center">
						<div className="d-felx ">
							<FontAwesomeIcon className="angle-icon" icon={faAngleLeft} />
							<FontAwesomeIcon className="angle-icon" icon={faAngleRight} />
						</div>
					</Card.Footer> */}
			</Card>
		</div>
	);
}
export default MakeRequest;
