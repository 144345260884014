import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import "@fontsource/noto-sans-bengali";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import DashboardRoutes from "../dashboardRoutes/dashboardRoutes";
import Home from "../LanndingPage/Home/Home.js";
import RequesterPerformer from "../RequesterPerfomer/RequesterPerfomer";
import Login from "../Login/Login";
function UserFront(props) {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" name="Home" component={Home} />
        <Route
          exact
          path="/requester-performer"
          name="RequesterPerformer"
          component={RequesterPerformer}
        />
        <Route exact path="*" name="Request" component={DashboardRoutes} />
      </Switch>
    </Router>
  );
}
export default withRouter(UserFront);
