import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Campaign from "../../../assets/images/h-1.png";
import Tasks from "../../../assets/images/h-2.png";
import Results from "../../../assets/images/h-3.png";

function HowItWorks() {

    return (
        <section className="how-it-works">
            <Container fluid className="container-width">

                <div className="section-content">
                    <h3>How It Works</h3>
                    <p>Settling for less is not an option! Elevate your Twitter presence and conquer the game of engagement, followers, and sales in three easy steps.</p>
                </div>
                <Row className="">
                    <Col lg={4}>
                        <div className="item">
                            <div className="icon">
                                <img src={Campaign} alt="Campaign" className="img-fluid" />
                            </div>
                            <h5>Create Tasks</h5>
                            <p>Set up your account and start creating tasks for likes, retweets, and followers to quickly build an engaged and relevant audience.</p>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="item">
                            <div className="icon">
                                <img src={Tasks} alt="Tasks" className="img-fluid" />
                            </div>
                            <h5>Attract Followers</h5>
                            <p>Verify the tasks performed by other Twitter Shilling users. After approval, performers will receive PRPL crypto tokens as a reward.</p>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="item">
                            <div className="icon">
                                <img src={Results} alt="Results" className="img-fluid" />
                            </div>
                            <h5>Analyze Growth</h5>
                            <p>Track daily growth and audience impact on your Twitter account. Monitor relevant followers' profiles, interests, and interactions. </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
export default HowItWorks;
