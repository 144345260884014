import React from "react";
import { Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import SiteLogo from "../../assets/images/logo.png";
function Header(props) {
  let token = JSON.parse(localStorage.getItem("token"));
  return (
    <header id="header" className="main-header">
      <Navbar collapseOnSelect expand="lg">
        <Container fluid className="container-width">
          <Navbar.Brand href="#home">
            <Link className="logo" to="/">
                          <img src={SiteLogo} alt=" Site Logo" className="img-fluid" />
            </Link>
          </Navbar.Brand>
          {!token ?
            (
              <Link to="/login" className="btn-yellow btn">
                Launch App
              </Link>
            ) : (
              <Link to="/request" className="btn-yellow btn">
                Launch App
              </Link>
            )}
          {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
                         
             
            </Nav>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
         
    </header>
  );
}
export default Header;
