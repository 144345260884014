import React, { useEffect, useState } from "react";
import { Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { OnboardingButton } from "../../utils/metaMaskconnect";
import Twitter from "../RequestPage/Twitter/twitter";
import { useParams, useHistory } from "react-router-dom";
import { ENV } from "../../config/config";
export default function Request() {
  const pathename = window.location.pathname;
  const user = ENV.getUserKeys();
  let history = useHistory();
  useEffect(() => {
    if (user.data) {
      if (user.data.type === 0) {
        history.push({  
          pathname: "/requester-performer",
        });
      }
    }
  }, [user]);
  return (
    <>
      <Row className="align-items-center">
        <Col xl={4}>
          <div className="header-logo">
            <img src="/images/logo.png" alt="Logo" className="img-fluid"/>
          </div>
        </Col>
        <Col xl={8} className="mt-xl-0 mt-3 mb-xl-0 mb-3">
          <div className="connect">
            <OnboardingButton />
            <Twitter />
          </div>
        </Col>
      </Row>
      <Nav variant="tabs" className="mb-3">
        <Nav.Item>
          <Link
            to="/request"
            className={
              pathename === "/request" ? "active nav-link" : "nav-link"
            }
          >
            Active
          </Link>
        </Nav.Item>
        {user.data?.type === 2 ? (
          <>
            <Nav.Item>
              <Link
                to="/accepted-request"
                className={
                  pathename === "/accepted-request"
                    ? "active nav-link"
                    : "nav-link"
                }
              >
                Accepted Requests
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/claim"
                className={
                  pathename === "/claim" ? "active nav-link" : "nav-link"
                }
              >
                Claim
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/claim-token"
                className={
                  pathename === "/claim-token" ? "active nav-link" : "nav-link"
                }
              >
                Claim Request
              </Link>
            </Nav.Item>
          </>
        ) : (
          <>
            <Nav.Item>
              <Link
                to="/make-request"
                className={
                  pathename === "/make-request" ? "active nav-link" : "nav-link"
                }
              >
                Make Request
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/my-request"
                className={
                  pathename === "/my-request" ? "active nav-link" : "nav-link"
                }
              >
                My Requests
              </Link>
            </Nav.Item>
          </>
        )}

        {/* <Nav.Item>
                    <Link to="/accepted-request" className="nav-link">Accepted Requests</Link>
                </Nav.Item>

                <Nav.Item>
                    <Link to="/claim" className="nav-link">Staking</Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/make-request" className="nav-link">Make Request</Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/my-request" className="nav-link">My Requests</Link>
                </Nav.Item> */}
      </Nav>
    </>
  );
}
