import {
  SET_PAYMENT_TRANSITION,
  SET_CLAIM_TRANSITION,
  POST_TRANSCACTION,
  GET_ERRORS,
} from "../actions/actionType";
import { apiHelper } from "./apiHelper";
import { toast } from "react-toastify";

export const ClaimPrpl = (data) => async (dispatch) => {
  let res = await apiHelper("post", `v1/front/users`);

  try {
    if (res) {
      if (res.data.success) {
        toast.success(res.data.message);
        dispatch({
          type: SET_CLAIM_TRANSITION,
          payload: res,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload: res.data,
    });
  }
};

export const PaymentTransaction = (data) => async (dispatch) => {
  let res = await apiHelper("post", `v1/front/users`);
  try {
    if (res) {
      if (res.data.success) {
        toast.success(res.data.message);
        dispatch({
          type: SET_PAYMENT_TRANSITION,
          payload: res,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload: res.data,
    });
  }
};

export const depositTranscations = (body) => async (dispatch) => {
  let res = await apiHelper("post", `v1/front/transactions`, body);
  try {
    if (res) {
      if (res.data.success) {
        toast.success(res.data.message);
        dispatch({
          type: POST_TRANSCACTION,
          payload: res,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload: res.data,
    });
  }
};
