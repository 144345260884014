import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Earn from "../../../assets/images/earn-money.png";
import Money from "../../../assets/images/money.svg"; 
function EarnMoney() {

    return (
        <section className="earn-money">
            <Container fluid className="container-width">
                <Row className="">
                    <Col lg={6}>
                        <div className="image">
                            <img className="img-fluid" src={Earn} alt=" Site Logo" />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="section-content">
                            <div className="icon">
                                <img className="img-fluid" src={Money} alt=" Site Logo" />
                            </div>
                            <h3>Earn Crypto Rewards by Helping Others Succeed</h3>
                            <p>Are you looking for a way for money while helping businesses and organizations increase their visibility and reach on Twitter? Then, Twitter Shilling is undoubtedly a perfect ‘opportunity for you. By performing the tasks of Twitter account holders to get more followers and elevate engagement, you can have a great source of passive income. </p>
                            <p>Earn crypto while also contributing to the success of others. With your strong social media presence and marketing skills, you can make a real impact and create valuable connections. </p>
                            <p className="text-blue fw-600">Start your journey to earning cryptocurrency with Twitter Shilling.</p>
                            <Link to="/request" className="btn btn-yellow">Join As Performer</Link>
                        </div>
                    </Col>

                </Row>
            </Container>
        </section>
    );
}
export default EarnMoney;
