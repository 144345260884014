import React, { useEffect, useState } from "react";
import { Row, Col, DropdownButton, Dropdown, Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import DashboradHeader from "../../DashboardHeader/DashboardHeader";
import { getUser } from "../../../Redux/actions/authActions";
import { ClaimToken } from "../../../Redux/actions/requestActions";
import { useDispatch, useSelector } from "react-redux";
import { ENV } from "../../../config/config";
import { MetaMaskConnection } from "../../../utils/metaMaskconnect";
import {useHistory} from "react-router-dom"
let Wallet_Address = localStorage.getItem("WalletAddress");

function StakingPage(props) {
  const loggedInUser = useSelector((state) => state?.auth.userData);
  const user = ENV.getUserKeys();
  const history = useHistory()
  const userId = user.data?._id;
  const dispatch = useDispatch();
  const [amount, setAmount] = useState("");
  const [errors, setErrors] = useState("");
  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      window.location.replace("/login");
    }
  }, []);
  useEffect(() => {
    dispatch(getUser(user.data?._id));
  }, []);
  const claimSubmit = () => {
    debugger
    if (amount === "") {
      setErrors("Enter the Balance");
    } else {
      if (amount > loggedInUser.data?.rewards) {
        setErrors("You have Insufficient Tokens");
      } else {
        setErrors("");
        const wallet_address = Wallet_Address;
        const payload = {
          userId,
          amount,
          wallet_address,
        };
        if (wallet_address) {
          dispatch(ClaimToken(payload));
          history.push("/claim-token")
        } else {
          MetaMaskConnection();
        }
      }
    }
  };
  return (
    <div className="tabone-content">
      <DashboradHeader />
      <Card>
        <Card.Body className="make-request-tab">
          <div className="staking-tab">
            <Row className="align-items-center">
              {/* <div className="stake-content">
                <Link to="" className="learn-more2">
                  Learn more
                  <FontAwesomeIcon
                    className="filter-color"
                    icon={faExternalLinkAlt}
                  />
                </Link>
              </div> */}
              <Col sm={8}>
                <div className="staking-tab-inner">
                  <h1>Claim your PRPL.</h1>
                  <h3>Earn rewards.</h3>
                </div>
              </Col>
              <Col sm={4}>
                <div className="d-flex justify-content-sm-end pt-sm-0 pt-3 justify-content-center">
                  <h3>Total PRPL</h3>
                </div>
                  <h3 className="d-flex justify-content-sm-end justify-content-center">{loggedInUser && loggedInUser?.data? loggedInUser?.data?.rewards:0}</h3>
              </Col>
            </Row>
            {/* <Row className="align-items-center">
              <Col lg={12}>
                <div className="stake-details">
                  <ol>
                    <li>
                      Add liquidity
                      <p>
                        Head over to QuickSwap Exchange and add liquidity to the
                        PRPL/USDC liquidity pool
                      </p>
                      <div className="note">
                        <p>
                          Note: You are tranferred LP Tokens when you deposit
                          liqudity into a pool.
                        </p>
                      </div>
                    </li>
                    <li>
                      Stake LP Tokens
                      <p>
                        Come to this page and stake your PRPL/USDC LP Tokens
                      </p>
                    </li>
                    <li>
                      Earn PRPL
                      <p>Earn PRPL, paid from Requesters</p>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row> */}
            <Row className="align-items-end mt-3">
              <Col xl={6} lg={6}>
                <div className="claim-prpl bg-gray-box">
                  <div className="balance-input-field mb-2">
                    <Form.Control
                      className=""
                      name="amount"
                      value={amount}
                      type="text"
                      placeholder="0.00"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  {errors ? (
                    <div
                      style={{ color: "#FE6E00" }}
                      className="alert text-danger ps-0 p-1 mb-2"
                    >
                      {errors}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex balance-button">
                    <button
                      className="btn-yellow btn"
                      onClick={claimSubmit}
                    >
                      Claim PRPL
                    </button>
                  </div>
                </div>
              </Col>
              {/* <Col xl={6} lg={6}>
                <div className="d-flex flex-column balance-section bg-gray-box">
                  <p>PRPL/USDC Balance: 0</p>
                  <div className="d-flex flex-sm-row flex-column justify-content-between">
                    <div className="usdc-field balance-input-field me-2">
                      <Form.Control
                        className=""
                        size="lg"
                        type="text"
                        placeholder="0.00"
                      />
                    </div>
                    <div className="d-flex  max-button">
                      <button className="btn-yellow btn">Max</button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-sm-between stake-common-btn flex-sm-row flex-column">
                    <div className="d-flex balance-button">
                      <button className="btn-yellow btn">
                        Stake PRPL/USDC
                      </button>
                    </div>
                    <div className="d-flex balance-button">
                      <button className="btn-yellow btn">
                        Stake PRPL/USDC
                      </button>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
export default StakingPage;
