import React from "react";
import { Row, Col } from "react-bootstrap";
import { updateUserType } from "../../Redux/actions/authActions";
import { ENV } from "../../config/config";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

export default function RequesterPerfomer() {
  const user = ENV.getUserKeys();
  const history = useHistory();
  let token = JSON.parse(localStorage.getItem("token"));
  const dispatch = useDispatch();

  const setPermanentRole = async (role) => {
    let data = {
      type: parseInt(role),
      username: user?.data.username,
      id: user?.data._id,
      token: token,
    };

    if (user.data.type === 0) {
      await dispatch(updateUserType(data));
      history.push("/request");
    }
    else {
      history.push("/request");
    }
  };

  return (
    <div>
      <section id="request">
        <div className="container-fluid container-width">
          <div className="requester-petformer">
            <div className="d-flex justify-content-center align-items-center">
              <h2>How would you like to start?</h2>
            </div>
            <div className="detail-section">
              <Row className="align-items-center justify-content-center">
                <Col
                  lg={6}
                >
                  <div className="item d-flex flex-column align-items-center justify-content-center" >
                    <h5> As Requester</h5>
                    <p>Make sure you select the correct role</p>
                    <div className="d-flex mt-md-5 mt-2">
                      <button
                        className="btn btn-yellow"
                        onClick={(e) => {
                          setPermanentRole("1");
                        }}
                      >
                        {" "}
                        REQUESTER
                      </button>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={6}
                >
                  <div className="item d-flex  flex-column align-items-center justify-content-center">
                    <h5> As Performer</h5>
                    <p>Make sure you select the correct role</p>
                    <div className="d-flex mt-md-5 mt-2">
                      <button
                        className="btn btn-yellow"
                        onClick={(e) => {
                          setPermanentRole("2");
                        }}
                      >
                        {" "}
                        PERFORMER
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
