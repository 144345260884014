// import { combineReducers } from "redux";
import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import reqReducer from "./requestReducer";

import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk'

const middleware = [thunk]
const reducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  gig: reqReducer
});


const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store