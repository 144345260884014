import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PRPL from "../../../assets/images/prpl.png";
function WhyPrpl() {

    return (
        <section className="why-prpl">
            <Container fluid className="container-width">
                <Row className="">
                    <Col lg={6}>
                        <div className="section-content">

                            <h3>Why PRPL?</h3>
                            <p>PRPL is a custom token designed to ensure</p>
                            <ul>
                                <li>1. Enhanced Value: PRPL tokens provide users additional value by increasing the rewards earned from Twitter Shilling participation. This means you can make more rewards for your efforts on Twitter Shilling than other token systems.</li>
                                <li>2. Efficient Value Transfer: PRPL tokens streamline the value transfer process by eliminating intermediaries. This makes digital transactions faster and more efficient and reduces associated costs.</li>
                                <li>3. Cost-Effective Transactions: PRPL tokens can reduce the cost of transactions, making it more cost-effective for users to participate in the platform.</li>
                                <li>4. Secure Transactions: PRPL tokens are built on a secure blockchain platform, providing high transaction transparency and security. This helps to build trust and confidence between users and the platform.</li>
                            </ul>
                            {/* <Link to="/" className="text-blue">For more info, read the PRPL documentation…  </Link> */}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="image">
                            <img className="img-fluid" src={PRPL} alt=" Site Logo" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
export default WhyPrpl;
