import axios from "axios";
import { toast } from "react-toastify";
import { apiHelper } from "./apiHelper";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  SET_REQUEST_DATA,
  REQUESTS_LIST,
  DELETE_GIG,
  REQUESTS_LIST_ID,
  VIEW_GIG,
  ACCEPT_GIG,
  SET_PROOF,
  UPDATE_PROOF,
  REQUESTS_DETAIL_BY_ID,
  SET_PROOF_ID,
  CLAIM_TOKEN,
  CLAIM_TOKEN_REQUEST,
} from "./actionType";
const baseUrl = process.env.REACT_APP_SERVER_URL;

export const RequestPackage = (userData) => async (dispatch) => {
  let res = await apiHelper("post", `v1/front/gig/create`, userData);
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        dispatch({
          type: SET_REQUEST_DATA,
          payload: data.newGig,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};
export const RequestsListByID = (id, page, limit, sort) => async (dispatch) => {
  let res = await apiHelper(
    "get",
    `v1/front/gig/list/?userId=${id}&page=${page}&limit=${limit}&sort=${sort}`
  );
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        dispatch({
          type: REQUESTS_LIST_ID,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};
export const ViewGigBYId = (id) => async (dispatch) => {
  let res = await apiHelper("get", `v1/front/gig/${id}`, "");
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        dispatch({
          type: VIEW_GIG,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};
export const RequestsList = (id, page, limit, sort) => async (dispatch) => {
  let res = await apiHelper(
    "get",
    `v1/front/gig/list/?id=${id}&page=${page}&limit=${limit}&sort=${sort}`,
    ""
  );
  try {
    let { data } = res;
    if (data.success) {
      dispatch({
        type: REQUESTS_LIST,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_ERRORS,
        payload:data,
      });
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};
export const AcceptGig = (userData) => async (dispatch) => {
  let res = await apiHelper("post", `v1/front/gig/accept`, userData);
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        dispatch({
          type: ACCEPT_GIG,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};
export const uploadProof = (userData) => async (dispatch) => {
  let res = await apiHelper("post", `v1/front/gig/upload-proof`, userData);
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        toast.success(data.message);
        dispatch({
          type: UPDATE_PROOF,
          payload: data
        });
        return data.success;
      } else {
        toast.error(data.message);
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};
export const gigHistory = (id, page, limit, sort) => async (dispatch) => {
  let res = await apiHelper(
    "get",
    `v1/front/gig/accepted-requests/?userId=${id}&page=${page}&limit=${limit}&sort=${sort}`,
    {}
  );
  try {
    if (res) {
      let { data } = res;

      if (data.success) {
        dispatch({
          type: SET_PROOF,
          payload: data.data,
        });
      } else {
        
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};

export const acceptedGigbyId = (id) => async (dispatch) => {
  console.log(id);
  let res = await apiHelper(
    "get",
    `v1/front/gig/accepted-request-detail/${id}`,
    {}
  );
  try {
    if (res) {
      let { data } = res;

      if (data.success) {
        dispatch({
          type: SET_PROOF_ID,
          payload: data.data,
        });
      } else {
        
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};

export const loginUser = (userData) => (dispatch) => {
  axios
    .post(`${baseUrl}user`, userData)
    .then((res) => {})
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const deletegig = (Id) => (dispatch) => {
  let url = `${baseUrl}v1/front/gig/delete/${Id}`;

  fetch(url, {
    method: "DELETE",
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        toast.success(data.message)
        dispatch({
          type: DELETE_GIG,
          payload: data.admin,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const RequestsDetailByID = (id) => async (dispatch) => {
  let res = await apiHelper(
    "get",
    `v1/front/gig/accepted-request-detail/${id}`
  );
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        dispatch({
          type: REQUESTS_DETAIL_BY_ID,
          payload: data,
        });
      } else {
        
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};

export const performerResToProof = (data) => async (dispatch) => {
  let res = await apiHelper("post", `v1/front/gig/update-proof`, data);
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        toast.success(data.message);
        dispatch({
          type: UPDATE_PROOF,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};

export const ClaimToken = (userData) => async (dispatch) => {
  let res = await apiHelper("post", `v1/front/users/claim-request`, userData);
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        toast.success(data.message);
        dispatch({
          type: CLAIM_TOKEN,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload: data,
    });
  }
};

export const ClaimRequest = (id, page, limit) => async (dispatch) => {
  let res = await apiHelper(
    "get",
    `v1/front/users/claim-requests/?userId=${id}&page=${page}&limit=${limit}`
  );
  try {
    if (res) {
      let { data } = res;
      if (data.success) {
        dispatch({
          type: CLAIM_TOKEN_REQUEST,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload:data,
        });
      }
    }
  } catch (e) {
    let { data } = res;
    dispatch({
      type: GET_ERRORS,
      payload:data,
    });
  }
};
