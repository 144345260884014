import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ViewGigBYId, AcceptGig } from "../../../Redux/actions/requestActions";
import Swal from "sweetalert2";
import { ENV } from "../../../config/config";
import {
  OnboardingButton,
  MetaMaskConnection,
} from "../../../utils/metaMaskconnect";
import Twitter from "./../Twitter/twitter";
import { ProgressBar } from "react-bootstrap";
import FullPageLoader from "../../FullPageLoader/fullPageLoader";

function RequstDetails(props) {
  let Wallet_Address = " "
  const user = ENV.getUserKeys();
  const username = user?.data.username;
  let history = useHistory();
  const [datas, setData] = useState([]);
  const [accepted, setAccepted] = useState(false);
  const [loader, setLoader] = useState(true);
  const accept = false;
  let complete_percentage = "0";
  const { id } = useParams();
  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token"));
    Wallet_Address = localStorage.getItem("WalletAddress");
    if (!token) {
      window.location.replace("/login");
    } else {
      props.ViewGigBYId(id);
      setLoader(true)
    }
  }, []);
  useEffect(() => {
    const { viewGig } = props.list;
    const userId = user?.data._id;
    const gigId = id;
    const wallet_address = Wallet_Address;
    const payload = { userId, gigId, accept, wallet_address, username };
    if (viewGig[0]?.userId) {
      props.AcceptGig(payload);
      setAccepted(false);
    }
    const gigData = viewGig[0];

    setData(gigData);
  }, [props.list.viewGig]);

  useEffect(() => {
    const { accepted } = props.list;
    const { success } = accepted;
    setAccepted(success);
  }, [props.list.accepted]);
  const acceptGigs = () => {
    Swal.fire({
      title: "Are you sure you want to Accept?",
      html: "Accept The Gig .",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Accept",
    }).then((result) => {

      if (result.value) {
        const { _id } = datas;
        const userId = user.data?._id;
        const gigId = _id;
        const wallet_address = Wallet_Address;
        const payload = {
          userId,
          gigId,
          username,
          wallet_address,
          accept: true,
        };
        if (Wallet_Address) {
          props.AcceptGig(payload);
          history.push("/accepted-request");
        } else {
          MetaMaskConnection();
        }
      }
    });
  };
  {
    complete_percentage = Math.floor(
      (((datas?.likesCount
        ? datas.likesCount
        : 0) + (datas?.retweetsCount
          ? datas?.retweetsCount
          : 0) + (datas?.followsCount
            ? datas.followsCount
            : 0)) /
        (datas?.likes + datas?.retweet + datas?.follows)) *
      100
    );
  }
  useEffect(() => {
    if (datas) {
      setLoader(false)
    }
  }, [datas])
  return (
    <>{
      loader ? (
        <FullPageLoader />
      ) : (
        <section id="request">
          <div className="">
            <Row className="align-items-center mb-md-5 mb-2">
              <Col xl={6}>
                <div className="header-logo">
                  <img src="/images/logo.png" alt="Logo" className="img-fluid" />
                </div>
              </Col>
              <Col xl={6} className="mt-xl-0 mt-3 mb-xl-0 mb-3">
                <div className="connect">
                  <OnboardingButton />
                  <Twitter />
                </div>
              </Col>
            </Row>
            <div className="d-flex go-back back-btn-style mb-sm-3 mb-2 align-items-left">
              <button
                onClick={() => {
                  history.goBack();
                }}
              >
                <FontAwesomeIcon className="me-2 faArrowLeft" icon={faArrowLeft} />
                Go back
              </button>
            </div>
            <div className="tabone-content">
              <Card>
                <Card.Body className="make-request-tab reqest-detail">
                  <div className="">
                    <div className="d-flex flex-sm-row flex-column justify-content-center mb-md-5 mb-2">
                      <div className="d-flex">
                        <h2>Request Details</h2>
                      </div>
                    </div>

                    {datas?.likes ? (
                        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mb-3">
                        <div className="d-flex">
                          <h4>Like</h4>
                        </div>
                        <div className=" d-flex questioner-input-field custom-w">
                          <input
                            placeholder="Twitter URL"
                            type="text"
                            className="form-control form-control-lg"
                            onChange={() => { }}
                            value={datas?.twitterUrl}
                            readOnly
                          />
                        </div>
                      </div>
                    ) : null}

                    {datas?.follows ? (
                        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mt-2 mb-3">
                        <div className="d-flex">
                          <h4>Follow</h4>
                        </div>

                        <div className=" d-flex questioner-input-field custom-w">
                          <input
                            placeholder="Twitter URL"
                            type="text"
                            className="form-control form-control-lg"
                            onChange={() => { }}
                            value={datas?.twitterUrl}
                            readOnly
                          />
                        </div>
                      </div>
                    ) : null}
                    {datas?.retweet ? (
                        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mt-2 mb-3">
                        <div className="d-flex">
                          <h4>Retweet</h4>
                        </div>

                        <div className=" d-flex questioner-input-field custom-w">
                          <input
                            placeholder="Twitter URL"
                            type="text"
                            className="form-control form-control-lg bg-blue"
                            onChange={() => { }}
                            value={datas?.twitterUrl}
                            readOnly
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-between align-items-center mt-md-5 mt-2 price-per-req">
                      <div className="d-flex">
                        <h3>Price per requests</h3>
                      </div>
                      <div className="d-flex flex-sm-row flex-column">
                        <p>
                          Likes: &nbsp;<b>{datas?.likesPrice} PRPL</b> &nbsp; Follow:&nbsp;
                          <b>{datas?.followsPrice} PRPL</b> &nbsp; Retweets: &nbsp; <b>{datas?.retweetsPrice} PRPL</b>
                        </p>
                      </div>
                    </div>
                    <div className="bg-gray-box">
                      <div className="d-flex price-per-req heading-p">
                        <p>Progress</p>
                      </div>
                      <ProgressBar
                        now={complete_percentage}
                        label={`${complete_percentage}
                %`}
                      />

                      <div className="d-flex justify-content-center align-items-center mt-md-5 mt-2 reward-text">
                        <div className="d-flex">
                          <h3>PRPL Rewards:</h3>
                        </div>
                        <div className=" d-flex">
                          <h1>{datas?.totalPrice} PRPL</h1>
                        </div>
                      </div>
                      {user.data.type === 1 ? null : accepted ===
                        true ? null : datas?.userId == user.data?._id ? null : (
                          <div className="d-flex flex-fill justify-content-center mt-md-5 mt-3">


                            <button
                                className="btn-yellow make-request-btn2 btn"
                              onClick={acceptGigs}
                            >
                              Accept the Request
                            </button>

                          </div>
                        )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>)
    }
    </>
  );
}
const mapStateToProps = (state) => ({
  list: state.gig,
  error: state.error,
});
export default connect(mapStateToProps, { ViewGigBYId, AcceptGig })(
  RequstDetails
);
