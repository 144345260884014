import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  RequestsListByID,
  deletegig,
} from "../../../Redux/actions/requestActions";
import Swal from "sweetalert2";
import DashboradHeader from "../../DashboardHeader/DashboardHeader";
import { ENV } from "../../../config/config";
import { ClaimPrpl } from "../../../utils/web3";
import { MetaMaskConnection } from "../../../utils/metaMaskconnect";
import { ProgressBar } from "react-bootstrap"
import { getUser } from "../../../Redux/actions/authActions";
import FullPageLoader from "../../FullPageLoader/fullPageLoader";
import moment from "moment";

function YourRequest(props) {
  // var user = JSON.parse(localStorage.getItem("user"));
  const user = ENV.getUserKeys();

  const [list, setList] = useState(null);
  const [currentPage, setCurrentPage] = useState("1");
  const [totalPage, setTotalPage] = useState(null);
  const [limit, setLimit] = useState("10");
  const [totalgigs, setTotalgigs] = useState(null);
  const [filter, setFilter] = useState("createdAt");
  const [userInfo, setUserInfo] = useState(null);
  const [loader, setLoader] = useState(true)
  let complete_percentage = "0"
  const userId = user.data?._id;
  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      window.location.replace("/login");
    } else {
      props.RequestsListByID(userId, currentPage, limit, filter);
      props.getUser(userId)
    }
  }, []);
useEffect(() => {
  setLoader(false)
}, [list])
  const deleteGigs = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      html: "If you delete a gig, it would be permanently lost.",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.value) {
        props.deletegig(id);
        if (result.value) {
          props.RequestsListByID(userId, currentPage, limit);
        }
      }
    });
  };
  useEffect(() => {
    const { gig } = props.list;
    setList(gig);
  }, [props.list.gig]);
  useEffect(() => {
    const { userData } = props.user;
    setUserInfo(userData);
  }, [props.user])
  useEffect(() => {
    const { pagination } = props.list;
    setCurrentPage(pagination.page);
    setTotalPage(pagination.pages);
    setTotalgigs(pagination.total);
  }, [props.list.pagination]);
  const handleSelect = (e) => {
    setLimit(e);
    props.RequestsListByID(userId, 1, e, filter);
  };
  const prevPage = () => {
    if (currentPage >= 1) {
      props.RequestsListByID(userId, currentPage - 1, limit, filter);
    }
  };
  const nextPage = () => {
    if (currentPage >= 1 && currentPage < totalPage) {
      props.RequestsListByID(userId, currentPage + 1, limit, filter);
    }
  };
  const FilterData = (e) => {
    setFilter(e.target.value);
    props.RequestsListByID(userId, currentPage, limit, e.target.value);
  };
  const claimPRPL = async () => {
    let WalletAddress = await localStorage.getItem("WalletAddress");

    if (WalletAddress) {
      await ClaimPrpl(user.data?.rewards);
    } else {
      MetaMaskConnection();
    }
  };
  return (
    <>
      <div className="tabone-content active-page accepted-request">
        <DashboradHeader />
        {loader ? (
          <FullPageLoader />
        ) : (
          <Card>
            <Card.Header>
              <div className="d-flex flex-md-row flex-column justify-content-between">
                <div className="d-flex justify-content-between align-items-center mt-md-0 mt-3">
                  <div className="d-flex filters-action me-4">
                    <button className="btn btn-white" onChange={FilterData}>
                      <FontAwesomeIcon className="filter-color" icon={faFilter} />
                      Filter
                      <div className="dropdown-menu">
                        <div className="d-flex">
                          <p>value by:</p>
                        </div>
                        <div className="d-flex justify-content-lg-between transition option-values">
                          <div className="d-flex">
                            <input
                              type="radio"
                              id="f-option"
                              value="createdAt"
                              name="selector"
                            />
                            <label htmlFor="f-option">All</label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-lg-between transition option-values">
                          <div className="d-flex">
                            <input
                              type="radio"
                              id="s-option"
                              value="likesPrice"
                              name="selector"
                            />
                            <label htmlFor="s-option">Like</label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-lg-between transition option-values">
                          <div className="d-flex">
                            <input
                              type="radio"
                              id="t-option"
                              value="followsPrice"
                              name="selector"
                            />
                            <label htmlFor="t-option">Follow</label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-lg-between transition option-values">
                          <div className="d-flex">
                            <input
                              type="radio"
                              id="R-option"
                              value="retweetsPrice"
                              name="selector"
                            />
                            <label htmlFor="R-option">Retweet</label>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                  {/* <div className="d-flex">
                    <Link to="" className="learn-more">
                      Learn more
                      <FontAwesomeIcon
                        className="filter-color"
                        icon={faExternalLinkAlt}
                      />
                    </Link>
                  </div> */}
                </div>
                { /*   <div className="d-flex justify-content-between align-items-center mt-md-0 mt-3">
                <div className="d-flex me-4 text-prpl">
                  <p>
                    {userInfo?.data?.rewards}
                    <span>PRPL</span>
                  </p>
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-yellow"
                    onClick={() => claimPRPL()}
                  >
                    Claim PRPL
                  </button>
                </div>
              </div>
  */}
              </div>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <Table
                  className="request-table tab-one-table"
                  style={{ marginBottom: "0" }}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Request</th>
                      <th>Date</th>
                      <th className="text-center">Remaining</th>
                      <th>Total</th>
                      <th>Price per request</th>
                      <th className="text-center">pprl rewards</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list.length
                      ? list.map((data, index) => {
                        {
                          complete_percentage = Math.floor(
                            (((data?.likesCount ? data.likesCount : 0) +
                              (data?.retweetsCount ? data.retweetsCount : 0) +
                              (data?.followsCount ? data.followsCount : 0)) /
                              (data?.likes + data?.retweet + data?.follows)) *
                            100
                          )
                        }
                        return (
                          <tr key={index}>
                            <td className="text-center view-req">
                              <Link to={`/prpl-dashborad/${data._id}`}>
                                <div className="shape-up d-flex">
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                                View Request
                              </Link>
                            </td>
                            <td>
                              <div className="d-flex flex-column">
                                <div className="d-flex">
                                  <div className="social-icons table-social-links">
                                    <ul>
                                      <li>
                                        {data.likes ? (
                                          <a
                                            href={data?.twitterUrl}
                                            target="_blank" rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faHeart} />
                                          </a>
                                        ) : null}
                                      </li>
                                      <li>
                                        {data.retweet ? (
                                          <a
                                            href={data?.twitterUrl}
                                            target="_blank" rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faRetweet} />
                                          </a>
                                        ) : null}
                                      </li>
                                      <li>
                                        {data.follows ? (
                                          <a
                                            href={data?.twitterUrl}
                                            target="_blank" rel="noreferrer"
                                          >
                                            <FontAwesomeIcon
                                              icon={faUserPlus}
                                            />
                                          </a>
                                        ) : null}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="d-flex next-page-info">
                                  <Link to={data.twitterUrl}>Tweet Url</Link>
                                </div>
                              </div>
                            </td>
                            <td> 
                              <p>  {moment(data?.activationDate).format("DD MMM YYYY")} - {moment(data?.expirationDate).format("DD MMM YYYY")}</p>
                            </td>
                            <td className="custome-de-pr">
                              <ProgressBar now={complete_percentage} label={`${complete_percentage}
                            %`} />

                              <p className="text-center">
                                {data?.likes ? `Likes : ${data?.likes - data?.likesCount}` : null}{" "}
                                {data?.follows
                                  ? `Follow : ${data?.follows - data?.followsCount}`
                                  : null}{" "}
                                {data?.retweet
                                  ? `Retweets : ${data?.retweet - data?.retweetsCount}`
                                  : null}
                              </p>
                            </td>
                            <td>
                              <div className="d-flex flex-column">

                                {data?.likes ? <p>Likes : {data?.likes} </p> : null}{" "}
                                {data?.follows
                                  ? <p> Follow : {data?.follows}</p>
                                  : null}{" "}
                                {data?.retweet
                                  ? <p>Retweets : {data?.retweet}</p>
                                  : null}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-column">
                                {data.likes ? <p>Like: {data.likesPrice} PRPL</p> : null}
                                {data.follows ? (
                                  <p>Follow: {data.followsPrice} PRPL</p>
                                ) : null}
                                {data.retweet ? (
                                  <p>Retweet: {data.retweetsPrice} PRPL </p>
                                ) : null}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex text-prpl justify-content-center">
                                <p>{data.totalPrice} PRPL</p>
                              </div>
                            </td>
                            <td className="text-center">
                              {/* <Link to={`/makeRequest/${data._id} `} >  <FontAwesomeIcon className="text-black action-icon" icon={faEdit} /> </Link> */}

                              <FontAwesomeIcon
                                className="text-black action-icon ms-3"
                                icon={faTrashAlt}
                                onClick={() => deleteGigs(data._id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer className="footer-items">
              <div className="d-felx justify-content-md-end justify-content-center">
                <>
                  <InputGroup className="rows-per-page d-flex align-items-center justify-content-end justify-content-md-end justify-content-center">
                    <p>Rows per page : {limit}</p>
                    <DropdownButton
                      title=""
                      id="input-group-dropdown-1"
                      name="limit"
                      onSelect={handleSelect}
                    >
                      <Dropdown.Item eventKey="5"> 5</Dropdown.Item>
                      <Dropdown.Item eventKey="10"> 10</Dropdown.Item>
                      <Dropdown.Item eventKey="20"> 20</Dropdown.Item>
                      <Dropdown.Item eventKey="50"> 50</Dropdown.Item>
                      <Dropdown.Item eventKey="100"> 100</Dropdown.Item>
                    </DropdownButton>
                    <div className="d-flex ms-md-5 ms-2">
                      <p>
                        {currentPage} - {totalPage} of {totalgigs}
                      </p>
                    </div>
                    <div className="d-flex custom-pagination">
                      <button
                        disabled={currentPage === 1 ? true : ""}
                        onClick={prevPage}
                      >
                        {" "}
                        <FontAwesomeIcon
                          className="angle-icon"
                          icon={faAngleLeft}
                        />
                      </button>
                      <button
                        disabled={currentPage === totalPage ? true : ""}
                        onClick={nextPage}
                      >
                        {" "}
                        <FontAwesomeIcon
                          className="angle-icon"
                          icon={faAngleRight}
                        />{" "}
                      </button>
                    </div>
                  </InputGroup>
                </>
              </div>
            </Card.Footer>
          </Card>)
        }
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  list: state.gig,
  user: state.auth,
  error: state.error,
});
export default connect(mapStateToProps, { RequestsListByID, deletegig, getUser })(
  YourRequest
);
