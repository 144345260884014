import React from "react";
import Header from "../../Includes/Header";
import MainBanner from "../../Includes/Banner/MainBanner";
import WhyPostProtocol from "../WhyPostProtocol/WhyPostProtocol";
import Footer from "../../Includes/Footer";
import HowItWorks from "../HowItWorks/HowItWorks";
import Engaged from "../Engaged/Engaged";
import EarnMoney from "../EarnMoney/EarnMoney";
import WhyPrpl from "../WhyPrpl/WhyPrpl";
import Performing from "../Performing/Performing";

function Home(props) {
    return (
        <React.Fragment>
            <div className="wraper">
                <Header />
                <MainBanner />
                <HowItWorks />
                <Engaged />
                <EarnMoney />
                <WhyPrpl />
                <Performing />
                <Footer />
                {/* <WhyPostProtocol /> */}
            </div>
        </React.Fragment>
    );
}
export default Home;
