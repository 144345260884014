import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { uploadProof, gigHistory } from "../../../Redux/actions/requestActions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import DashboradHeader from "../../DashboardHeader/DashboardHeader";
import { Button, Badge } from "react-bootstrap";
import { ENV } from "../../../config/config";
import { ClaimPrpl } from "../../../utils/web3";
import { MetaMaskConnection } from "../../../utils/metaMaskconnect";
import { ProgressBar } from "react-bootstrap";
import { getUser } from "../../../Redux/actions/authActions";
import FullPageLoader from "../../FullPageLoader/fullPageLoader";
const serverUrl = ENV.serverUrl;
function AcceptedRequests(props) {
  const user = ENV.getUserKeys();
  const WalletAddress = localStorage.getItem("WalletAddress");
  const loggedInUser = useSelector((state) => state?.auth.userData);
  const count = useSelector((state) => state?.gig.count);
  const accpectedGigs = useSelector((state) => state?.gig.accpectedGigs);
  const paginations = useSelector((state) => state?.gig.pagination);
  const userId = user?.data._id;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState("1");
  const [totalPage, setTotalPage] = useState(null);
  const [limit, setLimit] = useState("10");
  const [totalgigs, setTotalgigs] = useState(null);
  const [errors, setErrors] = useState("");
  const [filter, setFilter] = useState("createdAt");
  const [avatar, setAvatar] = useState({
    likeAvatar: "",
    likeAvatarName: "",
    followAvatar: "",
    followAvatarName: "",
    retweetAvatar: "",
    retweetAvatarName: "",
  });
  const [selectedId, setSelectedId] = useState("");
  const [loader, setLoader] = useState(true)
  let complete_percentage = " 0";
  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      window.location.replace("/login");
    }
  }, []);
  useEffect(() => {
    dispatch(getUser(user.data?._id));
  }, []);
  useEffect(() => {
    if (
      avatar.likeAvatarName !== "" ||
      avatar.followAvatarName !== "" ||
      avatar.retweetAvatarName !== ""
    ) {
      setAvatar({
        likeAvatar: "",
        likeAvatarName: "",
        followAvatar: "",
        followAvatarName: "",
        retweetAvatar: "",
        retweetAvatarName: "",
      });
    }
    dispatch(gigHistory(userId, currentPage, limit, filter));
  }, [count]);
  useEffect(() => {
    setCurrentPage(paginations.page);
    setTotalPage(paginations.pages);
    setLoader(false)
  }, [accpectedGigs]);
  const handleSelect = (e) => {
    setLimit(e);
    dispatch(gigHistory(userId, 1, e, filter));
  };
  const prevPage = () => {
    setCurrentPage(currentPage - 1);

    if (currentPage >= 1) {
      dispatch(gigHistory(userId, currentPage - 1, limit, filter));
    }
  };
  const nextPage = () => {
    if (currentPage >= 1 && currentPage < totalPage) {
      dispatch(gigHistory(userId, currentPage + 1, limit, filter));
    }
  };
  const FilterData = (e) => {
    setFilter(e.target.value);
    dispatch(gigHistory(userId, currentPage, limit, e.target.value));
  };

  const claimPRPL = async () => {
    let WalletAddress = await localStorage.getItem("WalletAddress");

    if (WalletAddress) {
      await ClaimPrpl(user?.data?.rewards);
    } else {
      MetaMaskConnection();
    }
  };
  console.log(user);
  return (
    <div className="tabone-content active-page accepted-request">
      <DashboradHeader />
      {loader ? (<FullPageLoader />) : (
        <Card>
          <Card.Header>
            <div className="d-flex flex-md-row flex-column justify-content-between">
              <div className="d-flex justify-content-between align-items-center mt-md-0 mt-3">
                <div className="d-flex filters-action me-4">
                  <button className="btn btn-white" onChange={FilterData}>
                    <FontAwesomeIcon className="filter-color" icon={faFilter} />
                    Filter
                    <div className="dropdown-menu">
                      <div className="d-flex">
                        <p>value by:</p>
                      </div>
                      <div className="d-flex justify-content-lg-between transition option-values">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="f-option"
                            value="createdAt"
                            name="selector"
                            onChange={() => { }}
                          />
                          <label htmlFor="f-option">All</label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-lg-between transition option-values">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="s-option"
                            value="likesPrice"
                            name="selector"
                            onChange={() => { }}
                          />
                          <label htmlFor="s-option">Like</label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-lg-between transition option-values">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="t-option"
                            value="followsPrice"
                            name="selector"
                            onChange={() => { }}
                          />
                          <label htmlFor="t-option">Follow</label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-lg-between transition option-values">
                        <div className="d-flex">
                          <input
                            type="radio"
                            id="R-option"
                            value="retweetsPrice"
                            name="selector"
                            onChange={() => { }}
                          />
                          <label htmlFor="R-option">Retweet</label>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
                {/* <div className="d-flex">
                  <Link className="learn-more">
                    Learn more
                    <FontAwesomeIcon
                      className="filter-color"
                      icon={faExternalLinkAlt}
                    />
                  </Link>
                </div> */}
              </div>
              <div className="d-flex justify-content-between align-items-center mt-md-0 mt-3">
                <div className="d-flex me-4 text-prpl">
                  <p>
                    {loggedInUser?.data?.rewards}
                    <span>PRPL</span>
                  </p>
                </div>
                <div className="d-flex">
                  <Link to="/claim" className="btn btn-yellow">
                    Claim PRPL
                  </Link>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <Table
                className="request-table tab-one-table"
                style={{ marginBottom: "0" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Request</th>
                    <th className="text-center">Remaining</th>
                    <th>Total</th>
                    <th>Price per request</th>
                    <th className="text-center">pprl rewards</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {accpectedGigs
                    ? accpectedGigs.map((data, key) => {
                      {
                        complete_percentage = Math.floor(
                          (((data.gig?.likesCount
                            ? data.gig?.likesCount
                            : 0) + (data.gig?.retweetsCount
                              ? data.gig?.retweetsCount
                              : 0) + (data.gig?.followsCount
                                ? data.gig?.followsCount
                                : 0)) /
                            (data?.gig?.likes +
                              data?.gig?.retweet +
                              data?.gig?.follows)) *
                          100
                        );
                      }
                      return (
                        <>
                          <tr key={key}>
                            <td className="text-center view-req">
                              <Link
                                to={`/accepted-request-details/${data?._id}`}
                              >
                                <div className="shape-up d-flex">
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                                View Details
                              </Link>
                            </td>
                            <td>
                              <div className="d-flex flex-column">
                                <div className="d-flex">
                                  <div className="social-icons table-social-links">
                                    <ul>
                                      <li>
                                        {data.gig?.likes ? (
                                          <a
                                            href={data.gig?.twitterUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faHeart} />
                                          </a>
                                        ) : null}
                                      </li>
                                      <li>
                                        {data.gig?.retweet ? (
                                          <a
                                            href={data.gig?.twitterUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon icon={faRetweet} />
                                          </a>
                                        ) : null}
                                      </li>
                                      <li>
                                        {data.gig?.follows ? (
                                          <a
                                            href={data.gig?.twitterUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon
                                              icon={faUserPlus}
                                            />
                                          </a>
                                        ) : null}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="d-flex next-page-info">
                                  <a href={data.gig?.twitterUrl}>Tweet Url</a>
                                </div>
                              </div>
                            </td>
                            <td className="custome-de-pr">
                              <ProgressBar
                                now={complete_percentage}
                                label={`${complete_percentage}
                            %`}
                              />
                              <p className="text-center">
                                {data?.gig?.likes
                                  ? `Likes : ${data?.gig?.likes - data?.gig?.likesCount}`
                                  : null}{" "}
                                {data?.gig?.follows
                                  ? `Follow : ${data?.gig?.follows - data?.gig?.followsCount}`
                                  : null}{" "}
                                {data?.gig?.retweet
                                  ? `Retweets : ${data?.gig?.retweet - data?.gig?.retweetsCount}`
                                  : null}
                              </p>
                            </td>
                            <td>
                              <p className="">
                                {data?.gig?.likes
                                  ? `Likes : ${data?.gig?.likes}`
                                  : null}{" "}
                              </p>
                              <p className="">
                                {data?.gig?.follows
                                  ? `Follow : ${data?.gig?.follows}`
                                  : null}{" "}
                              </p>
                              <p className="">
                                {data?.gig?.retweet
                                  ? `Retweets : ${data?.gig?.retweet}`
                                  : null}
                              </p></td>
                            <td>
                              <div className="d-flex flex-column">
                                {data?.gig?.likesPrice ? (
                                  <p>Like: {data?.gig?.likesPrice} PRPL </p>
                                ) : null}
                                {data?.gig?.followsPrice ? (
                                  <p>Follow: {data?.gig?.followsPrice} PRPL </p>
                                ) : null}
                                {data?.gig?.retweetsPrice ? (
                                  <p>Retweet: {data?.gig?.retweetsPrice} PRPL </p>
                                ) : null}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex text-prpl justify-content-center">
                                <p>{data.gig?.totalPrice} PRPL</p>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                    : ""}
                </tbody>
              </Table>
            </div>
          </Card.Body>
          <Card.Footer className="footer-items">
            <div className="d-felx justify-content-md-end justify-content-center">
              <>
                <InputGroup className="rows-per-page d-flex align-items-center justify-content-end justify-content-md-end justify-content-center">
                  <p>Rows per page : {limit}</p>
                  <DropdownButton
                    title=""
                    id="input-group-dropdown-1"
                    name="limit"
                    onSelect={handleSelect}
                  >
                    <Dropdown.Item eventKey="5"> 5</Dropdown.Item>
                    <Dropdown.Item eventKey="10"> 10</Dropdown.Item>
                    <Dropdown.Item eventKey="20"> 20</Dropdown.Item>
                    <Dropdown.Item eventKey="50"> 50</Dropdown.Item>
                    <Dropdown.Item eventKey="100"> 100</Dropdown.Item>
                  </DropdownButton>
                  <div className="d-flex ms-md-5 ms-2">
                    <p>
                      {paginations.page ? paginations.page : 0} -{" "}
                      {paginations.pages ? paginations.pages : 0} of{" "}
                      {paginations.total ? paginations.total : 0}
                    </p>
                  </div>
                  <div className="d-flex custom-pagination">
                    <button
                      disabled={currentPage === 1 ? true : ""}
                      onClick={prevPage}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="angle-icon"
                        icon={faAngleLeft}
                      />
                    </button>
                    <button
                      disabled={currentPage === totalPage ? true : ""}
                      onClick={nextPage}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="angle-icon"
                        icon={faAngleRight}
                      />{" "}
                    </button>
                  </div>
                </InputGroup>
              </>
            </div>
          </Card.Footer>
        </Card>)}
    </div>
  );
}
export default AcceptedRequests;
