export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

//Request tpes here
export const SET_REQUEST_DATA = "SET_REQUEST_DATA";
export const REQUESTS_LIST = "REQUESTS_LIST";
export const REGISTER_USER = "REGISTER_USER";
export const DELETE_GIG = "DELETE_GIG";
export const REQUESTS_LIST_ID = "REQUESTS_LIST_ID";
export const VIEW_GIG = "VIEW_GIG";
export const ACCEPT_GIG = "ACCEPT_GIG";
export const UPLOAD_LIKE = "UPLOAD_LIKE";
export const UPDATE_PROOF = "UPDATE_PROOF";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const SET_PROOF = "SET_PROOF";
export const REQUESTS_DETAIL_BY_ID = "REQUESTS_DETAIL_BY_ID";
export const SET_PROOF_ID = "SET_PROOF_ID";
export const GET_USERS = "GET_USERS";

// Web 3 transactions
export const SET_CLAIM_TRANSITION = "SET_CLAIM_TRANSITION";
export const SET_PAYMENT_TRANSITION = "SET_PAYMENT_TRANSITION";
export const CLAIM_TOKEN = " CLAIM_TOKEN";
export const CLAIM_TOKEN_REQUEST = " CLAIM_TOKEN_REQUEST"
export const POST_TRANSCACTION = " POST_TRANSCACTION"

